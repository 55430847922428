@charset "UTF-8";
@import "~perfect-scrollbar/css/perfect-scrollbar.css"; /*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
:root {
  --blue: #0d6efd;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #d63384;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0d6efd;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.5;
  color: #717171;
  text-align: left;
  background-color: #eff1f9;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #024dbc;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.375rem;
}

h2, .h2 {
  font-size: 1.9rem;
}

h3, .h3 {
  font-size: 1.6625rem;
}

h4, .h4 {
  font-size: 1.425rem;
}

h5, .h5 {
  font-size: 1.1875rem;
}

h6, .h6 {
  font-size: 0.95rem;
}

.lead {
  font-size: 1.1875rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.1875rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #eff1f9;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #717171;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #717171;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bbd6fe;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #81b4fe;
}

.table-hover .table-primary:hover {
  background-color: #a2c7fe;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a2c7fe;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #8bbafe;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.1875rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.83125rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.95rem;
  line-height: 1.5;
  color: #717171;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.83125rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.1875rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.83125rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.83125rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #717171;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.95rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #717171;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-primary:hover {
  color: #fff;
  background-color: #025ce2;
  border-color: #0257d5;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #025ce2;
  border-color: #0257d5;
  box-shadow: 0 0 0 0.2rem rgba(49, 132, 253, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0257d5;
  border-color: #0252c9;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 132, 253, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0d6efd;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: none;
}
.btn-link:hover {
  color: #024dbc;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.1875rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.83125rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.95rem;
  color: #717171;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.83125rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.1875rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.83125rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.425rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.2125rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #0d6efd;
  background-color: #0d6efd;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #8bbafe;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #bed8fe;
  border-color: #bed8fe;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.2125rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.2125rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0d6efd;
  background-color: #0d6efd;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(13, 110, 253, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(13, 110, 253, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(13, 110, 253, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.2125rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(13, 110, 253, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #8bbafe;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.83125rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.1875rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #8bbafe;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #eff1f9, 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #eff1f9, 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #eff1f9, 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #bed8fe;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #bed8fe;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #bed8fe;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #eff1f9;
  border-color: #dee2e6 #dee2e6 #eff1f9;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.321875rem;
  padding-bottom: 0.321875rem;
  margin-right: 1rem;
  font-size: 1.1875rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.1875rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0d6efd;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #024dbc;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.1875rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.83125rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #0d6efd;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0257d5;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.925rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #073984;
  background-color: #cfe2ff;
  border-color: #bbd6fe;
}
.alert-primary hr {
  border-top-color: #a2c7fe;
}
.alert-primary .alert-link {
  color: #042454;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.7125rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #717171;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #073984;
  background-color: #bbd6fe;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #073984;
  background-color: #a2c7fe;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #073984;
  border-color: #073984;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.425rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.83125rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.83125rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.95rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #717171;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #0d6efd !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0257d5 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0d6efd !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #0d6efd !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #024dbc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #717171 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
img {
    page-break-inside: avoid;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }
  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/* Google Font*/
html {
  font-family: "Roboto", sans-serif;
  height: 100vh;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "Roboto", sans-serif;
  background: #eff1f9;
  font-size: 0.95rem;
  color: #717171;
  letter-spacing: 0.5px;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

::placeholder {
  color: #9c9c9c !important;
  font-size: 13px;
  opacity: 0.5 !important;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9c9c9c !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9c9c9c !important;
}

#wrapper {
  width: 100%;
  position: relative;
}

/* Page Content Wrapper */
.content-wrapper {
  margin-left: 250px;
  padding-top: 70px;
  padding-bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-x: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #000;
}

.serif-font {
  font-family: "Roboto", sans-serif;
}

p {
  margin-bottom: 0.65rem;
}

:focus {
  outline: 0 !important;
}

a {
  color: #7934f3;
}
a:hover {
  color: #7934f3;
  text-decoration: none;
}
a.text-muted:focus, a.text-muted:hover {
  color: #748690;
}

label {
  color: #585858;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 10px;
}

.h1,
h1 {
  font-size: 48px;
  line-height: 52px;
}

.h2,
h2 {
  font-size: 38px;
  line-height: 42px;
}

.h3,
h3 {
  font-size: 30px;
  line-height: 34px;
}

.h4,
h4 {
  font-size: 24px;
  line-height: 28px;
}

.h5,
h5 {
  font-size: 18px;
  line-height: 22px;
}

.h6,
h6 {
  font-size: 14px;
  line-height: 18px;
}

.display-1 {
  font-size: 6rem;
}

.display-2 {
  font-size: 5.5rem;
}

.display-3 {
  font-size: 4.5rem;
}

.display-4 {
  font-size: 3.5rem;
}

.line-height-0 {
  line-height: 0;
}

.line-height-5,
.line-height-10 {
  line-height: 5px;
}

.blockquote-footer {
  color: #615f5f;
}

.small,
small {
  font-size: 75%;
  font-weight: 400;
}

.small-font {
  font-size: 14px;
}

.extra-small-font {
  font-size: 12px;
}

/* Tables */
table.grid {
  width: 100%;
  border: none;
  background-color: transparent;
  padding: 0px;
}
table.grid td {
  border: 2px solid #e8e8e8;
  padding: 8px;
}

.card .table {
  margin-bottom: 0px;
}
.card .table td,
.card .table th {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.table.align-items-center td,
.table.align-items-center th {
  vertical-align: middle;
}
.table thead th {
  font-size: 0.72rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.table-bordered {
  border: 1px solid rgba(37, 33, 33, 0.1);
}

.table-flush td,
.table-flush th {
  border-right: 0;
  border-left: 0;
}

.table td,
.table th {
  white-space: nowrap;
  border-top: 1px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
  border-top: 1px solid #dee2e6;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.table th {
  font-weight: 600;
}

.table-responsive {
  white-space: nowrap;
}

.table .thead-primary th {
  color: #fff;
  background-color: #7934f3;
  border-color: #7934f3;
}
.table .thead-secondary th {
  color: #fff;
  background-color: #94614f;
  border-color: #94614f;
}
.table .thead-success th {
  color: #fff;
  background-color: #04b962;
  border-color: #04b962;
}
.table .thead-danger th {
  color: #fff;
  background-color: #f43643;
  border-color: #f43643;
}
.table .thead-warning th {
  color: #fff;
  background-color: #ff8800;
  border-color: #ff8800;
}
.table .thead-info th {
  color: #fff;
  background-color: #14b6ff;
  border-color: #14b6ff;
}
.table .thead-dark th {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.table .thead-light th {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.125);
  border-color: rgba(255, 255, 255, 0.125);
}

.table-primary {
  color: #fff;
  background-color: #7934f3;
}
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: rgba(244, 245, 250, 0.15);
}

.table-secondary {
  color: #fff;
  background-color: #94614f;
}
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: rgba(244, 245, 250, 0.3);
}

.table-success {
  color: #fff;
  background-color: #04b962;
}
.table-success td,
.table-success th,
.table-success thead th {
  border-color: rgba(244, 245, 250, 0.3);
}

.table-danger {
  color: #fff;
  background-color: #f43643;
}
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: rgba(244, 245, 250, 0.3);
}

.table-warning {
  color: #fff;
  background-color: #ff8800;
}
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: rgba(244, 245, 250, 0.3);
}

.table-info {
  color: #fff;
  background-color: #14b6ff;
}
.table-info td,
.table-info th,
.table-info thead th {
  border-color: rgba(244, 245, 250, 0.3);
}

.table-dark {
  color: #fff;
  background-color: #353434;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: rgba(244, 245, 250, 0.15);
}

.table-light {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.14);
}
.table-light td,
.table-light th,
.table-light thead th {
  border-color: rgba(221, 222, 222, 0.22);
}

.table-active {
  background-color: rgba(255, 255, 255, 0.07);
}
.table-active > td,
.table-active > th {
  background-color: rgba(255, 255, 255, 0.07);
}

/* Forms */
label {
  color: #585858;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 10px;
}

form .form-header {
  color: #353434;
  line-height: 3rem;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.color-forms input.form-control {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.6);
}
.color-forms input.form-control:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.color-forms label {
  color: #fff;
}
.color-forms input::placeholder {
  color: #fff !important;
  font-size: 13px;
  opacity: 0.5 !important;
  /* Firefox */
}

.input-group .btn {
  box-shadow: none;
  padding: 0.375rem 0.75rem;
}

.input-group-text {
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}

.col-form-label {
  font-size: 13px;
}

.form-control {
  border: 1px solid #ced4da;
  background-color: rgba(0, 0, 0, 0);
  color: #495057 !important;
}
.form-control:focus {
  border: 1px solid #7934f3;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0.2rem rgba(121, 52, 243, 0.35);
}

.form-control-rounded {
  border-radius: 30px !important;
}

.form-control-square {
  border-radius: 0px !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: rgba(158, 158, 158, 0.33);
  opacity: 1;
}

.form-control-xl {
  height: 60px !important;
  font-size: 26px !important;
}

.position-relative {
  position: relative !important;
}

.has-icon-left .form-control {
  padding-right: 0.85rem;
  padding-left: 2.9rem;
}

.form-control-position {
  position: absolute;
  top: -8px;
  right: 0;
  z-index: 2;
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
}

.has-icon-left .form-control-position {
  right: auto;
  left: 0px;
}

.has-icon-right .form-control-position {
  right: 0px;
  left: auto;
}
.has-icon-right .form-control {
  padding-right: 37px;
}

.search-input {
  margin-bottom: 10px;
}

.custom-header {
  background: rgba(255, 255, 255, 0.34);
  padding: 5px;
  color: #ffffff;
}

.input-group-prepend [type=checkbox]:checked, .input-group-prepend [type=checkbox]:not(:checked) {
  position: initial;
  opacity: 1;
  margin-top: 0px;
}
.input-group-prepend [type=radio]:checked, .input-group-prepend [type=radio]:not(:checked) {
  position: initial;
  opacity: 1;
  margin-top: 0px;
}

/*Buttons */
.btn {
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 9px 19px;
  border-radius: 0.25rem;
  text-transform: uppercase;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.btn-link {
  color: #7934f3;
}

.btn:focus {
  box-shadow: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 12px 38px;
  font-size: 0.9rem;
}

.btn-sm, .btn-group-sm > .btn {
  font-size: 10px;
  font-weight: 500;
  padding: 6px 15px;
}

.btn-group-sm > .btn {
  font-size: 10px;
}

.btn-primary {
  color: #fff;
  background-color: #7934f3;
  border-color: #7934f3;
}
.btn-primary:hover {
  color: #fff;
  background-color: #6a27e0;
  border-color: #6a27e0;
}
.btn-primary.focus, .btn-primary:focus {
  box-shadow: none;
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #6a27e0;
  border-color: #6a27e0;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #6a27e0;
  border-color: #6a27e0;
}

.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #6a27e0;
  border-color: #6a27e0;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary {
  color: #fff;
  background-color: #94614f;
  border-color: #94614f;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #82503f;
  border-color: #82503f;
}
.btn-secondary.focus, .btn-secondary:focus {
  box-shadow: none;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #82503f;
  border-color: #82503f;
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #82503f;
  border-color: #82503f;
}

.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #82503f;
  border-color: #82503f;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-success {
  color: #fff;
  background-color: #04b962;
  border-color: #04b962;
}
.btn-success:hover {
  color: #fff;
  background-color: #019e4c;
  border-color: #019e4c;
}
.btn-success.focus, .btn-success:focus {
  box-shadow: none;
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #019e4c;
  border-color: #019e4c;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #019e4c;
  border-color: #019e4c;
}

.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #019e4c;
  border-color: #019e4c;
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-info {
  color: #fff;
  background-color: #14b6ff;
  border-color: #14b6ff;
}
.btn-info:hover {
  color: #fff;
  background-color: #039ce0;
  border-color: #039ce0;
}
.btn-info.focus, .btn-info:focus {
  box-shadow: none;
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #039ce0;
  border-color: #039ce0;
}
.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #039ce0;
  border-color: #039ce0;
}

.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #039ce0;
  border-color: #039ce0;
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-warning {
  color: #fff;
  background-color: #ff8800;
  border-color: #ff8800;
}
.btn-warning:hover {
  color: #fff;
  background-color: #e67c02;
  border-color: #e67c02;
}
.btn-warning.focus, .btn-warning:focus {
  box-shadow: none;
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #e67c02;
  border-color: #e67c02;
}
.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #e67c02;
  border-color: #e67c02;
}

.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #e67c02;
  border-color: #e67c02;
}

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-danger {
  color: #fff;
  background-color: #f43643;
  border-color: #f43643;
}
.btn-danger:hover {
  color: #fff;
  background-color: #de2935;
  border-color: #de2935;
}
.btn-danger.focus, .btn-danger:focus {
  box-shadow: none;
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #de2935;
  border-color: #de2935;
}
.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #de2935;
  border-color: #de2935;
}

.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #de2935;
  border-color: #de2935;
}

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-light {
  color: #2d2d2d;
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}
.btn-light:hover {
  color: #2d2d2d;
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}
.btn-light.focus, .btn-light:focus {
  box-shadow: none;
}
.btn-light.disabled, .btn-light:disabled {
  color: #2d2d2d;
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}
.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active {
  color: #2d2d2d;
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}

.show > .btn-light.dropdown-toggle {
  color: #2d2d2d;
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-light.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-light-2 {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.125);
  border-color: rgba(255, 255, 255, 0.125);
  border: 0;
}
.btn-light-2:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.125);
  border-color: rgba(255, 255, 255, 0.125);
  border: 0;
}
.btn-light-2.focus, .btn-light-2:focus {
  box-shadow: none;
}

.btn-dark {
  color: #fff;
  background-color: #0a151f;
  border-color: #0a151f;
}
.btn-dark:hover {
  color: #fff;
  background-color: #1b1a1a;
  border-color: #1b1a1a;
}
.btn-dark.focus, .btn-dark:focus {
  box-shadow: none;
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #1b1a1a;
  border-color: #1b1a1a;
}
.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #1b1a1a;
  border-color: #1b1a1a;
}

.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1b1a1a;
  border-color: #1b1a1a;
}

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-white {
  color: #0a151f;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:hover {
  color: #0a151f;
  background-color: #fff;
  border-color: #fff;
}
.btn-white.focus, .btn-white:focus {
  box-shadow: none;
}
.btn-white.disabled, .btn-white:disabled {
  color: #0a151f;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:not(:disabled):not(.disabled).active, .btn-white:not(:disabled):not(.disabled):active {
  color: #0a151f;
  background-color: #fff;
  border-color: #fff;
}

.show > .btn-white.dropdown-toggle {
  color: #0a151f;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:not(:disabled):not(.disabled).active:focus, .btn-white:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-white.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-primary {
  color: #7934f3;
  background-color: transparent;
  background-image: none;
  border-color: #7934f3;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #7934f3;
  border-color: #7934f3;
}
.btn-outline-primary.focus, .btn-outline-primary:focus {
  color: #fff;
  background-color: #7934f3;
  border-color: #7934f3;
  box-shadow: none;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #7934f3;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #7934f3;
  border-color: #7934f3;
}

.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #7934f3;
  border-color: #7934f3;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-secondary {
  color: #94614f;
  background-color: transparent;
  background-image: none;
  border-color: #94614f;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #94614f;
  border-color: #94614f;
}
.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  color: #fff;
  background-color: #94614f;
  border-color: #94614f;
  box-shadow: none;
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #94614f;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #94614f;
  border-color: #94614f;
}

.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #94614f;
  border-color: #94614f;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-success {
  color: #04b962;
  background-color: transparent;
  background-image: none;
  border-color: #04b962;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #04b962;
  border-color: #04b962;
}
.btn-outline-success.focus, .btn-outline-success:focus {
  color: #fff;
  background-color: #04b962;
  border-color: #04b962;
  box-shadow: none;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #04b962;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #04b962;
  border-color: #04b962;
}

.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #04b962;
  border-color: #04b962;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-info {
  color: #14b6ff;
  background-color: transparent;
  background-image: none;
  border-color: #14b6ff;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #14b6ff;
  border-color: #14b6ff;
}
.btn-outline-info.focus, .btn-outline-info:focus {
  color: #fff;
  background-color: #14b6ff;
  border-color: #14b6ff;
  box-shadow: none;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #14b6ff;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #14b6ff;
  border-color: #14b6ff;
}

.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #14b6ff;
  border-color: #14b6ff;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-warning {
  color: #ff8800;
  background-color: transparent;
  background-image: none;
  border-color: #ff8800;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #ff8800;
  border-color: #ff8800;
}
.btn-outline-warning.focus, .btn-outline-warning:focus {
  color: #fff;
  background-color: #ff8800;
  border-color: #ff8800;
  box-shadow: none;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ff8800;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #ff8800;
  border-color: #ff8800;
}

.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ff8800;
  border-color: #ff8800;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-danger {
  color: #f43643;
  background-color: transparent;
  background-image: none;
  border-color: #f43643;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #f43643;
  border-color: #f43643;
}
.btn-outline-danger.focus, .btn-outline-danger:focus {
  color: #fff;
  background-color: #f43643;
  border-color: #f43643;
  box-shadow: none;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #f43643;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #f43643;
  border-color: #f43643;
}

.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f43643;
  border-color: #f43643;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-light {
  color: rgba(255, 255, 255, 0.125);
  background-color: transparent;
  background-image: none;
  border-color: rgba(255, 255, 255, 0.125);
}
.btn-outline-light:hover {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.125);
  border-color: rgba(255, 255, 255, 0.125);
}
.btn-outline-light.focus, .btn-outline-light:focus {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.125);
  border-color: rgba(255, 255, 255, 0.125);
  box-shadow: none;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: rgba(255, 255, 255, 0.125);
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.125);
  border-color: rgba(255, 255, 255, 0.125);
}

.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.125);
  border-color: rgba(255, 255, 255, 0.125);
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-white {
  color: #ffffff;
  background-color: transparent;
  background-image: none;
  border-color: #ffffff;
}
.btn-outline-white:hover {
  color: #000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-white.focus, .btn-outline-white:focus {
  color: #000;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: none;
}
.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #353434;
  background-color: transparent;
}
.btn-outline-white:not(:disabled):not(.disabled).active, .btn-outline-white:not(:disabled):not(.disabled):active {
  color: #000;
  background-color: #ffffff;
  border-color: #ffffff;
}

.show > .btn-outline-white.dropdown-toggle {
  color: #000;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-white:not(:disabled):not(.disabled).active:focus, .btn-outline-white:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-dark {
  color: #353434;
  background-color: transparent;
  background-image: none;
  border-color: #353434;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #353434;
  border-color: #353434;
}
.btn-outline-dark.focus, .btn-outline-dark:focus {
  color: #fff;
  background-color: #353434;
  border-color: #353434;
  box-shadow: none;
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #353434;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #353434;
  border-color: #353434;
}

.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #353434;
  border-color: #353434;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-link {
  font-weight: 600;
  box-shadow: none;
}
.btn-link:hover, .btn-link:focus {
  text-decoration: none;
}

/* inverse buttons */
.btn-inverse-primary {
  color: #7934f3;
  background-color: rgba(121, 52, 243, 0.18);
  border-color: rgba(121, 52, 243, 0.18);
}
.btn-inverse-primary:hover {
  color: #7934f3;
  background-color: rgba(121, 52, 243, 0.18);
  border-color: rgba(121, 52, 243, 0.18);
}

.btn-inverse-danger {
  color: #f43643;
  background-color: rgba(244, 54, 67, 0.18);
  border-color: rgba(244, 54, 67, 0.18);
}
.btn-inverse-danger:hover {
  color: #f43643;
  background-color: rgba(244, 54, 67, 0.18);
  border-color: rgba(244, 54, 67, 0.18);
}

.btn-inverse-success {
  color: #04b962;
  background-color: rgba(4, 185, 98, 0.18);
  border-color: rgba(4, 185, 98, 0.18);
}
.btn-inverse-success:hover {
  color: #04b962;
  background-color: rgba(4, 185, 98, 0.18);
  border-color: rgba(4, 185, 98, 0.18);
}

.btn-inverse-info {
  color: #14b6ff;
  background-color: rgba(20, 182, 255, 0.18);
  border-color: rgba(20, 182, 255, 0.18);
}
.btn-inverse-info:hover {
  color: #14b6ff;
  background-color: rgba(20, 182, 255, 0.18);
  border-color: rgba(20, 182, 255, 0.18);
}

.btn-inverse-warning {
  color: #ff8800;
  background-color: rgba(255, 136, 0, 0.18);
  border-color: rgba(255, 136, 0, 0.18);
}
.btn-inverse-warning:hover {
  color: #ff8800;
  background-color: rgba(255, 136, 0, 0.18);
  border-color: rgba(255, 136, 0, 0.18);
}

.btn-inverse-secondary {
  color: #94614f;
  background-color: rgba(148, 97, 79, 0.18);
  border-color: rgba(148, 97, 79, 0.18);
}
.btn-inverse-secondary:hover {
  color: #94614f;
  background-color: rgba(148, 97, 79, 0.18);
  border-color: rgba(148, 97, 79, 0.18);
}

.btn-inverse-dark {
  color: #0a151f;
  background-color: rgba(10, 21, 31, 0.18);
  border-color: rgba(10, 21, 31, 0.18);
}
.btn-inverse-dark:hover {
  color: #0a151f;
  background-color: rgba(10, 21, 31, 0.18);
  border-color: rgba(10, 21, 31, 0.18);
}

/* gradient buttons */
.btn-gradient-primary {
  color: #fff;
  background-color: #8f50ff;
  border-color: #8f50ff;
  background: linear-gradient(to top, #8f50ff, #d13adf);
}
.btn-gradient-primary:hover {
  color: #fff;
  background-color: #8f50ff;
  border-color: #8f50ff;
  background: linear-gradient(to top, #8f50ff, #d13adf);
}

.btn-gradient-danger {
  color: #fff;
  background-color: #ff5447;
  border-color: #ff5447;
  background: linear-gradient(to top, #ff5447, #f1076f);
}
.btn-gradient-danger:hover {
  color: #fff;
  background-color: #ff5447;
  border-color: #ff5447;
  background: linear-gradient(to top, #ff5447, #f1076f);
}

.btn-gradient-success {
  color: #fff;
  background-color: #08a50e;
  border-color: #08a50e;
  background: linear-gradient(to top, #08a50e, #cddc35);
}
.btn-gradient-success:hover {
  color: #fff;
  background-color: #08a50e;
  border-color: #08a50e;
  background: linear-gradient(to top, #08a50e, #cddc35);
}

.btn-gradient-info {
  color: #fff;
  background-color: #00c8ff;
  border-color: #00c8ff;
  background: linear-gradient(to top, #00c8ff, #0072ff);
}
.btn-gradient-info:hover {
  color: #fff;
  background-color: #00c8ff;
  border-color: #00c8ff;
  background: linear-gradient(to top, #00c8ff, #0072ff);
}

.btn-gradient-warning {
  color: #fff;
  background-color: #f7971e;
  border-color: #f7971e;
  background: linear-gradient(to top, #f7971e, #ffd200);
}
.btn-gradient-warning:hover {
  color: #fff;
  background-color: #f7971e;
  border-color: #f7971e;
  background: linear-gradient(to top, #f7971e, #ffd200);
}

.btn-gradient-secondary {
  color: #fff;
  background-color: #fc00ff;
  border-color: #fc00ff;
  background: linear-gradient(to top, #fc00ff, #00dbde);
}
.btn-gradient-secondary:hover {
  color: #fff;
  background-color: #fc00ff;
  border-color: #fc00ff;
  background: linear-gradient(to top, #fc00ff, #00dbde);
}

.btn-round {
  border-radius: 30px !important;
}

.btn-square {
  border-radius: 0px !important;
}

.btn-group,
.btn-group-vertical {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.btn-group .btn,
.btn-group-vertical .btn {
  box-shadow: none !important;
}

.btn-group-round {
  border-radius: 30px;
}
.btn-group-round .btn {
  border-radius: 30px;
}

.btn-group.group-round {
  border-radius: 25px;
}
.btn-group.group-round > .btn:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.btn-group.group-round > .btn:last-child {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.btn-group-vertical.group-round-vertical {
  border-radius: 25px;
}
.btn-group-vertical.group-round-vertical > .btn:first-child {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.btn-group-vertical.group-round-vertical > .btn:last-child {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.split-btn-primary {
  border: 1px solid #6222d4 !important;
  background-color: #6222d4;
}
.split-btn-primary:hover {
  border: 1px solid #6222d4 !important;
  background-color: #6222d4;
}

.split-btn-success {
  border: 1px solid #039e54 !important;
  background-color: #039e54;
}
.split-btn-success:hover {
  border: 1px solid #039e54 !important;
  background-color: #039e54;
}

.split-btn-danger {
  border: 1px solid #d81f2c !important;
  background-color: #d81f2c;
}
.split-btn-danger:hover {
  border: 1px solid #d81f2c !important;
  background-color: #d81f2c;
}

.split-btn-secondary {
  border: 1px solid #7d4f3f !important;
  background-color: #7d4f3f;
}
.split-btn-secondary:hover {
  border: 1px solid #7d4f3f !important;
  background-color: #7d4f3f;
}

.split-btn-warning {
  border: 1px solid #d67301 !important;
  background-color: #d67301;
}
.split-btn-warning:hover {
  border: 1px solid #d67301 !important;
  background-color: #d67301;
}

.split-btn-info {
  border: 1px solid #049ee4 !important;
  background-color: #049ee4;
}
.split-btn-info:hover {
  border: 1px solid #049ee4 !important;
  background-color: #049ee4;
}

.split-btn-white {
  border: 1px solid #dddddd !important;
  background-color: #dddddd;
}
.split-btn-white:hover {
  border: 1px solid #dddddd !important;
  background-color: #dddddd;
}

.split-btn-dark {
  border: 1px solid #1b1a1a !important;
  background-color: #1b1a1a;
}
.split-btn-dark:hover {
  border: 1px solid #1b1a1a !important;
  background-color: #1b1a1a;
}

/* Dropdown Menu */
.dropdown-menu {
  border: 0px solid rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 5px 15px rgba(57, 98, 254, 0.02), 0 3px 6px rgba(117, 108, 254, 0.12) !important;
  box-shadow: 0 5px 15px rgba(57, 98, 254, 0.02), 0 3px 6px rgba(117, 108, 254, 0.12) !important;
  font-size: 15px;
  background-color: #fff;
  color: #353434;
}

.topbar-nav .navbar .dropdown-menu::after {
  content: "";
  width: 13px;
  height: 13px;
  background: #fff;
  position: absolute;
  top: -7px;
  right: 20px;
  z-index: -1;
  transform: rotate(45deg);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.dropdown-menu ul {
  margin-top: 0px;
}

.dropdown-divider {
  margin: 0;
  border-top: 1px solid rgba(66, 59, 116, 0.15);
}

.dropdown-item {
  padding: 0.7rem 1.5rem;
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
}
.dropdown-item:hover {
  padding: 0.7rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.04);
  transition: all 0.2s ease;
}
.dropdown-item.active, .dropdown-item:active {
  color: #505050;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

.dropdown-toggle-nocaret:after {
  display: none;
}

.dropdown-lg {
  position: relative;
}
.dropdown-lg .dropdown-menu {
  width: 320px;
  padding: 0;
}
.dropdown-lg .dropdown-menu .list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
}
.dropdown-lg .dropdown-menu .list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}
.dropdown-lg .dropdown-menu .list-group-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  transition: all 0.2s ease;
}
.dropdown-lg .dropdown-menu .media .avatar img {
  width: 55px;
  height: 55px;
  border-radius: 4%;
  border: 2px solid #ffffff;
  -webkit-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}
.dropdown-lg .dropdown-menu .media .media-body .msg-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.84);
  margin-bottom: 0px;
}
.dropdown-lg .dropdown-menu .media .media-body .msg-info {
  font-size: 13px;
  color: #6b6b6b;
  margin-bottom: 0;
  white-space: nowrap;
}

/* Topbar Header */
.topbar-nav .navbar {
  padding: 0px 25px;
  z-index: 999;
  height: 60px;
  background: #fff;
  -webkit-box-shadow: 0 5px 15px rgba(57, 98, 254, 0.02), 0 3px 6px rgba(117, 108, 254, 0.12);
  box-shadow: 0 5px 15px rgba(57, 98, 254, 0.02), 0 3px 6px rgba(117, 108, 254, 0.12);
}

.toggle-menu {
  margin-left: 250px;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 32px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.08);
  text-align: center;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.right-nav-link a.nav-link {
  padding-right: 0.7rem !important;
  padding-left: 0.7rem !important;
  font-size: 20px;
  color: #353434;
}

/* SearachBar */
.search-bar {
  padding: 0rem 20rem 0 1rem;
  width: auto;
}

.search-arrow-back,
.search-btn-mobile {
  display: none;
}

.search-bar .btn-search-back {
  background: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 20px;
  border-radius: 0px;
}
.search-bar .btn-search-back i {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.65);
}
.search-bar .btn-search {
  background: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0 30px !important;
  border-radius: 0px;
  /* height: calc(1.3em + .75rem + 2px); */
}
.search-bar .btn-search i {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.65);
}
.search-bar input {
  height: calc(1.3em + 0.75rem + 2px);
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 0px;
}
.search-bar input:focus {
  border: 1px solid #7934f3;
  box-shadow: none;
}

/*Nav Tabs & Pills */
.nav-tabs .nav-link {
  color: #4c4c4c;
  font-size: 12px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 0;
  padding: 12px 20px;
  text-transform: uppercase;
  border: 0px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover {
  border: 0px solid transparent;
}
.nav-tabs .nav-link i {
  margin-right: 2px;
  font-weight: 600;
}

.top-icon.nav-tabs .nav-link i {
  margin: 0px;
  font-weight: 500;
  display: block;
  font-size: 20px;
  padding: 5px 0;
}

.color-tabs .nav-link {
  color: #fff;
}
.color-tabs.nav-tabs {
  border-bottom: 1px solid #fff;
}
.color-tabs .nav-link.active,
.color-tabs .nav-item.show > .nav-link {
  color: #fff;
  background-color: transparent;
  border-color: #fff #fff #fff;
  border-bottom: 4px solid #fff;
}

.nav-tabs-primary.nav-tabs {
  border-bottom: 1px solid #7934f3;
}
.nav-tabs-primary .nav-link.active,
.nav-tabs-primary .nav-item.show > .nav-link {
  color: #7934f3;
  background-color: transparent;
  border-color: #7934f3 #7934f3 #fff;
  border-bottom: 4px solid #7934f3;
}

.nav-tabs-success.nav-tabs {
  border-bottom: 1px solid #04b962;
}
.nav-tabs-success .nav-link.active,
.nav-tabs-success .nav-item.show > .nav-link {
  color: #04b962;
  background-color: transparent;
  border-color: #04b962 #04b962 #fff;
  border-bottom: 4px solid #04b962;
}

.nav-tabs-info.nav-tabs {
  border-bottom: 1px solid #14b6ff;
}
.nav-tabs-info .nav-link.active,
.nav-tabs-info .nav-item.show > .nav-link {
  color: #14b6ff;
  background-color: transparent;
  border-color: #14b6ff #14b6ff #fff;
  border-bottom: 4px solid #14b6ff;
}

.nav-tabs-danger.nav-tabs {
  border-bottom: 1px solid #f43643;
}
.nav-tabs-danger .nav-link.active,
.nav-tabs-danger .nav-item.show > .nav-link {
  color: #f43643;
  background-color: transparent;
  border-color: #f43643 #f43643 #fff;
  border-bottom: 3px solid #f43643;
}

.nav-tabs-warning.nav-tabs {
  border-bottom: 1px solid #ff8800;
}
.nav-tabs-warning .nav-link.active,
.nav-tabs-warning .nav-item.show > .nav-link {
  color: #ff8800;
  background-color: transparent;
  border-color: #ff8800 #ff8800 #fff;
  border-bottom: 4px solid #ff8800;
}

.nav-tabs-dark.nav-tabs {
  border-bottom: 1px solid #353434;
}
.nav-tabs-dark .nav-link.active,
.nav-tabs-dark .nav-item.show > .nav-link {
  color: #353434;
  background-color: #fff;
  border-color: #353434 #353434 #fff;
  border-bottom: 4px solid #353434;
}

.nav-tabs-secondary.nav-tabs {
  border-bottom: 1px solid #94614f;
}
.nav-tabs-secondary .nav-link.active,
.nav-tabs-secondary .nav-item.show > .nav-link {
  color: #94614f;
  background-color: transparent;
  border-color: #94614f #94614f #fff;
  border-bottom: 4px solid #94614f;
}

.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #dee2e6;
}
.tabs-vertical .nav-tabs .nav-link {
  color: #4c4c4c;
  font-size: 12px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 2px;
  margin-right: -1px;
  padding: 12px 1px;
  text-transform: uppercase;
  border: 1px solid transparent;
  border-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: 1px solid #dee2e6;
  border-right: 0;
  border-left: 1px solid #dee2e6;
}

.tabs-vertical-primary.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #7934f3;
}
.tabs-vertical-primary.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical-primary.tabs-vertical .nav-tabs .nav-link.active {
  color: #7934f3;
  background-color: transparent;
  border-color: #7934f3 #7934f3 #fff;
  border-bottom: 1px solid #7934f3;
  border-right: 0;
  border-left: 3px solid #7934f3;
}

.tabs-vertical-success.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #04b962;
}
.tabs-vertical-success.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical-success.tabs-vertical .nav-tabs .nav-link.active {
  color: #04b962;
  background-color: transparent;
  border-color: #04b962 #04b962 #fff;
  border-bottom: 1px solid #04b962;
  border-right: 0;
  border-left: 3px solid #04b962;
}

.tabs-vertical-info.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #14b6ff;
}
.tabs-vertical-info.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical-info.tabs-vertical .nav-tabs .nav-link.active {
  color: #14b6ff;
  background-color: transparent;
  border-color: #14b6ff #14b6ff #fff;
  border-bottom: 1px solid #14b6ff;
  border-right: 0;
  border-left: 3px solid #14b6ff;
}

.tabs-vertical-danger.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #f43643;
}
.tabs-vertical-danger.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical-danger.tabs-vertical .nav-tabs .nav-link.active {
  color: #f43643;
  background-color: transparent;
  border-color: #f43643 #f43643 #fff;
  border-bottom: 1px solid #f43643;
  border-right: 0;
  border-left: 3px solid #f43643;
}

.tabs-vertical-warning.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #ff8800;
}
.tabs-vertical-warning.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical-warning.tabs-vertical .nav-tabs .nav-link.active {
  color: #ff8800;
  background-color: transparent;
  border-color: #ff8800 #ff8800 #fff;
  border-bottom: 1px solid #ff8800;
  border-right: 0;
  border-left: 3px solid #ff8800;
}

.tabs-vertical-dark.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #353434;
}
.tabs-vertical-dark.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical-dark.tabs-vertical .nav-tabs .nav-link.active {
  color: #353434;
  background-color: transparent;
  border-color: #353434 #353434 #fff;
  border-bottom: 1px solid #353434;
  border-right: 0;
  border-left: 3px solid #353434;
}

.tabs-vertical-secondary.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #94614f;
}
.tabs-vertical-secondary.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical-secondary.tabs-vertical .nav-tabs .nav-link.active {
  color: #94614f;
  background-color: transparent;
  border-color: #94614f #94614f #fff;
  border-bottom: 1px solid #94614f;
  border-right: 0;
  border-left: 3px solid #94614f;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
  color: #4c4c4c;
  font-size: 12px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 3px;
  padding: 12px 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.nav-pills .nav-link:hover {
  background-color: transparent;
}
.nav-pills .nav-link i {
  margin-right: 2px;
  font-weight: 600;
}

.top-icon.nav-pills .nav-link i {
  margin: 0px;
  font-weight: 500;
  display: block;
  font-size: 20px;
  padding: 5px 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #7934f3;
}

.color-pills .nav-link {
  color: #fff;
}
.color-pills .nav-link:hover, .color-pills .nav-link.active {
  color: #353434;
  background-color: #fff;
}
.color-pills .show > .nav-link {
  color: #353434;
  background-color: #fff;
}

.nav-pills-success .nav-link.active,
.nav-pills-success .show > .nav-link {
  color: #fff;
  background-color: #04b962;
}

.nav-pills-info .nav-link.active,
.nav-pills-info .show > .nav-link {
  color: #fff;
  background-color: #14b6ff;
}

.nav-pills-danger .nav-link.active,
.nav-pills-danger .show > .nav-link {
  color: #fff;
  background-color: #f43643;
}

.nav-pills-warning .nav-link.active,
.nav-pills-warning .show > .nav-link {
  color: #fff;
  background-color: #ff8800;
}

.nav-pills-dark .nav-link.active,
.nav-pills-dark .show > .nav-link {
  color: #fff;
  background-color: #353434;
}

.nav-pills-secondary .nav-link.active,
.nav-pills-secondary .show > .nav-link {
  color: #fff;
  background-color: #94614f;
}

.nav-pills-custom .nav-link.active,
.nav-pills-white .show > .nav-link {
  color: #fff;
  background: linear-gradient(180deg, #8f50ff, #d13adf);
}

.nav-pills-custom .nav-link {
  padding: 6px 14px;
  border-radius: 1.25rem;
  font-size: 12px;
}

.card .tab-content {
  padding: 1rem 0 0 0;
}

/* Accordions */
#accordion1 .card-header button:before,
#accordion2 .card-header button:before,
#accordion3 .card-header button:before,
#accordion4 .card-header button:before,
#accordion5 .card-header button:before,
#accordion6 .card-header button:before,
#accordion7 .card-header button:before,
#accordion8 .card-header button:before {
  float: left !important;
  font-family: FontAwesome;
  content: "\f105";
  padding-right: 15px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#accordion1 .card-header button.collapsed:before,
#accordion2 .card-header button.collapsed:before,
#accordion3 .card-header button.collapsed:before,
#accordion4 .card-header button.collapsed:before,
#accordion5 .card-header button.collapsed:before,
#accordion6 .card-header button.collapsed:before,
#accordion7 .card-header button.collapsed:before,
#accordion8 .card-header button.collapsed:before {
  content: "\f107";
}

/*Cards */
.card {
  margin-bottom: 25px;
  box-shadow: 0 5px 15px rgba(57, 98, 254, 0.02), 0 3px 6px rgba(117, 108, 254, 0.12);
  border: none;
  background-color: #ffffff;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.84);
}

.card-title {
  margin-bottom: 0.75rem;
  font-weight: 600;
  font-size: 16px;
  color: #353434;
}

.card-action {
  float: right;
}
.card-action a i {
  color: #353434;
  border-radius: 50%;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.card-deck {
  margin-bottom: 25px;
}

@media (min-width: 576px) {
  .card-deck {
    margin-right: -12.5px;
    margin-left: -12.5px;
  }
  .card-deck .card {
    margin-right: 12.5px;
    margin-left: 12.5px;
  }
}
.card-group {
  box-shadow: 0 5px 15px rgba(57, 98, 254, 0.02), 0 3px 6px rgba(117, 108, 254, 0.12);
  margin-bottom: 25px;
}
.card-group .card {
  box-shadow: none;
}

.bubble {
  position: relative;
  overflow: hidden;
}
.bubble .card-block:before, .bubble .card-block:after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.22);
  right: -40px;
  border-radius: 50%;
}
.bubble .card-block:before {
  width: 100px;
  height: 100px;
  bottom: -40px;
}
.bubble .card-block:after {
  width: 80px;
  height: 80px;
  bottom: 40px;
}

/*Badges*/
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-up {
  position: absolute;
  top: 4px;
  right: 4px;
  border-radius: 50%;
  font-size: 10px;
  width: 20px;
  height: 20px;
  background: #ffffff;
  line-height: 20px;
  text-align: center;
  color: #2c2c2c;
  font-weight: 600;
}

.badge-primary {
  color: #fff;
  background-color: #7934f3;
}

.badge-secondary {
  color: #fff;
  background-color: #94614f;
}

.badge-success {
  color: #fff;
  background-color: #04b962;
}

.badge-danger {
  color: #fff;
  background-color: #f43643;
}

.badge-warning {
  color: #fff;
  background-color: #ff8800;
}

.badge-info {
  color: #fff;
  background-color: #14b6ff;
}

.badge-light {
  color: #212529;
  background-color: #f1f1f1;
}

.badge-dark {
  color: #fff;
  background-color: #353434;
}

.alert-custom {
  color: #7934f3;
  background-color: rgba(122, 52, 243, 0.2274509804);
  border-color: #7934f3;
}

/* Alerts*/
.alert {
  position: relative;
  margin-bottom: 1rem;
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
}
.alert .alert-icon {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 60px;
  font-size: 20px;
}
.alert .alert-message {
  display: table-cell;
  padding: 20px 15px 20px 15px;
  font-size: 14px;
}

.alert-dismissible .close {
  position: absolute;
  top: 0px;
  right: 0;
  font-weight: 300;
  padding: 10px 15px;
  color: inherit;
}

.alert .contrast-alert {
  background-color: rgba(255, 255, 255, 0.2);
}

.alert-success {
  color: #ffffff;
  background-color: #04b962;
  border-color: #04b962;
}
.alert-success .alert-link {
  color: #7bff2b;
}

.alert-info {
  color: #fefefe;
  background-color: #14b6ff;
  border-color: #14b6ff;
}
.alert-info .alert-link {
  color: #bef6ff;
}

.alert-danger {
  color: #ffffff;
  background-color: #f43643;
  border-color: #f43643;
}
.alert-danger .alert-link {
  color: #ffcacf;
}

.alert-warning {
  color: #fff;
  background-color: #ff8800;
  border-color: #ff8800;
}
.alert-warning .alert-link {
  color: #fff900;
}

.alert-outline-success {
  color: #04b962;
  border: 1px solid #04b962;
  border-right: 4px solid #04b962;
  border-left: 4px solid #04b962;
}
.alert-outline-success .alert-link {
  color: #04b962;
}

.alert-outline-info {
  color: #14b6ff;
  border: 1px solid #14b6ff;
  border-right: 4px solid #14b6ff;
  border-left: 4px solid #14b6ff;
}
.alert-outline-info .alert-link {
  color: #14b6ff;
}

.alert-outline-danger {
  color: #f43643;
  border: 1px solid #f43643;
  border-right: 4px solid #f43643;
  border-left: 4px solid #f43643;
}
.alert-outline-danger .alert-link {
  color: #f43643;
}

.alert-outline-warning {
  color: #ff8800;
  border: 1px solid #ff8800;
  border-right: 4px solid #ff8800;
  border-left: 4px solid #ff8800;
}
.alert-outline-warning .alert-link {
  color: #ff8800;
}

.alert-icon-success {
  color: #04b962;
  border: 1px solid #04b962;
}

.icon-part-success {
  background: #04b962;
  color: #fff;
  border-right: 1px solid #08b713;
}

.alert-icon-success .alert-link {
  color: #04b962;
}

.alert-icon-info {
  color: #14b6ff;
  border: 1px solid #14b6ff;
}

.icon-part-info {
  background: #14b6ff;
  color: #fff;
  border-right: 1px solid #09b9d4;
}

.alert-icon-info .alert-link {
  color: #14b6ff;
}

.alert-icon-danger {
  color: #f43643;
  border: 1px solid #f43643;
}

.icon-part-danger {
  background: #f43643;
  color: #fff;
  border-right: 1px solid #de2840;
}

.alert-icon-danger .alert-link {
  color: #f43643;
}

.alert-icon-warning {
  color: #ff8800;
  border: 1px solid #ff8800;
}

.icon-part-warning {
  background: #ff8800;
  color: #fff;
  border-right: 1px solid #e08500;
}

.alert-icon-warning .alert-link {
  color: #ff8800;
}

.box {
  padding: 60px 0px;
}

.box-part {
  background: #fff;
  border-radius: 0;
  padding: 60px 10px;
  margin: 30px 0px;
}

.alert-close-btn {
  top: 8px !important;
}

ngb-progressbar {
  margin-top: 5rem;
}
ngb-progressbar .progress {
  height: 1rem;
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Paginations */
.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #7934f3;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dee2e6;
  box-shadow: 0 0.125rem 0.25rem rgba(80, 73, 73, 0.06);
}
.page-link:hover {
  z-index: 2;
  color: #7934f3;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(121, 52, 243, 0.35);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 1;
  color: #7934f3;
  background-color: #fff;
  border-color: #7934f3;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-round .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
}
.pagination-round .page-item:last-child .page-link {
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
}

.pagination-separate .page-item .page-link {
  margin-left: 4px;
}

.pagination-outline-primary .page-item.disabled .page-link,
.pagination-outline-success .page-item.disabled .page-link,
.pagination-outline-info .page-item.disabled .page-link,
.pagination-outline-danger .page-item.disabled .page-link,
.pagination-outline-warning .page-item.disabled .page-link .pagination-outline-dark .page-item.disabled .page-link,
.pagination-outline-secondary .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-outline-primary .page-item .page-link {
  color: #7934f3;
  border: 1px solid #7934f3;
}
.pagination-outline-primary .page-item .page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #7934f3;
  border-color: #7934f3;
}
.pagination-outline-primary .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  color: #fff;
  background-color: #7934f3;
  box-shadow: 0 0 0 0.2rem rgba(121, 52, 243, 0.35);
}
.pagination-outline-primary .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #7934f3;
  border-color: #7934f3;
}

.pagination-outline-success .page-item .page-link {
  color: #04b962;
  border: 1px solid #04b962;
}
.pagination-outline-success .page-item .page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #04b962;
  border-color: #04b962;
}
.pagination-outline-success .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  color: #fff;
  background-color: #04b962;
  box-shadow: 0 0 0 0.2rem rgba(4, 185, 98, 0.35);
}
.pagination-outline-success .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #04b962;
  border-color: #04b962;
}

.pagination-outline-info .page-item .page-link {
  color: #14b6ff;
  border: 1px solid #14b6ff;
}
.pagination-outline-info .page-item .page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #14b6ff;
  border-color: #14b6ff;
}
.pagination-outline-info .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  color: #fff;
  background-color: #14b6ff;
  box-shadow: 0 0 0 0.2rem rgba(20, 182, 255, 0.35);
}
.pagination-outline-info .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #14b6ff;
  border-color: #14b6ff;
}

.pagination-outline-danger .page-item .page-link {
  color: #f43643;
  border: 1px solid #f43643;
}
.pagination-outline-danger .page-item .page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #f43643;
  border-color: #f43643;
}
.pagination-outline-danger .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  color: #fff;
  background-color: #f43643;
  box-shadow: 0 0 0 0.2rem rgba(244, 54, 67, 0.35);
}
.pagination-outline-danger .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #f43643;
  border-color: #f43643;
}

.pagination-outline-warning .page-item .page-link {
  color: #ff8800;
  border: 1px solid #ff8800;
}
.pagination-outline-warning .page-item .page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #ff8800;
  border-color: #ff8800;
}
.pagination-outline-warning .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  color: #fff;
  background-color: #ff8800;
  box-shadow: 0 0 0 0.2rem rgba(255, 136, 0, 0.35);
}
.pagination-outline-warning .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #ff8800;
  border-color: #ff8800;
}

.pagination-outline-dark .page-item .page-link {
  color: #000000;
  border: 1px solid #000000;
}
.pagination-outline-dark .page-item .page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #000000;
  border-color: #000000;
}
.pagination-outline-dark .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  color: #fff;
  background-color: #000000;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.35);
}
.pagination-outline-dark .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.pagination-outline-secondary .page-item .page-link {
  color: #ff2fa0;
  border: 1px solid #ff2fa0;
}
.pagination-outline-secondary .page-item .page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #ff2fa0;
  border-color: #ff2fa0;
}
.pagination-outline-secondary .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 47, 160, 0.51);
}
.pagination-outline-secondary .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #ff2fa0;
  border-color: #ff2fa0;
}

.pagination-primary .page-item.disabled .page-link,
.pagination-success .page-item.disabled .page-link,
.pagination-info .page-item.disabled .page-link,
.pagination-danger .page-item.disabled .page-link,
.pagination-warning .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-primary .page-item .page-link {
  color: #ffffff;
  border: 1px solid #520eca;
  background: #7934f3;
}
.pagination-primary .page-item .page-link:hover {
  z-index: 2;
  color: #7934f3;
  text-decoration: none;
  background-color: transparent;
  border-color: #520eca;
}
.pagination-primary .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  border: 1px solid rgba(121, 52, 243, 0.42);
  box-shadow: 0 0 0 0.2rem rgba(121, 52, 243, 0.42);
}
.pagination-primary .page-item.active .page-link {
  z-index: 1;
  color: #7934f3;
  background-color: transparent;
  border-color: #520eca;
}

.pagination-success .page-item .page-link {
  color: #ffffff;
  border: 1px solid #07864f;
  background-color: #04b962;
}
.pagination-success .page-item .page-link:hover {
  z-index: 2;
  color: #04b962;
  text-decoration: none;
  background-color: transparent;
  border-color: #07864f;
}
.pagination-success .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(4, 185, 98, 0.35);
}
.pagination-success .page-item.active .page-link {
  z-index: 1;
  color: #04b962;
  background-color: transparent;
  border-color: #1bb171;
}

.pagination-info .page-item .page-link {
  color: #ffffff;
  border: 1px solid #019bb7;
  background-color: #14b6ff;
}
.pagination-info .page-item .page-link:hover {
  z-index: 2;
  color: #14b6ff;
  text-decoration: none;
  background-color: transparent;
  border-color: #019bb7;
}
.pagination-info .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(20, 182, 255, 0.35);
}
.pagination-info .page-item.active .page-link {
  z-index: 1;
  color: #14b6ff;
  background-color: transparent;
  border-color: #0ab5d4;
}

.pagination-danger .page-item .page-link {
  color: #fff;
  border: 1px solid #bb0d2f;
  background-color: #f43643;
}
.pagination-danger .page-item .page-link:hover {
  z-index: 2;
  color: #f43643;
  text-decoration: none;
  background-color: transparent;
  border-color: #bb0d2f;
}
.pagination-danger .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 54, 92, 0.52);
}
.pagination-danger .page-item.active .page-link {
  z-index: 1;
  color: #f43643;
  background-color: transparent;
  border-color: #bb0d2f;
}

.pagination-warning .page-item .page-link {
  color: #ffffff;
  border: 1px solid #c56900;
  background-color: #ff8800;
}
.pagination-warning .page-item .page-link:hover {
  z-index: 2;
  color: #ff8800;
  text-decoration: none;
  background-color: transparent;
  border-color: #c56900;
}
.pagination-warning .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 136, 0, 0.35);
}
.pagination-warning .page-item.active .page-link {
  z-index: 1;
  color: #ff8800;
  background-color: transparent;
  border-color: #c56900;
}

.pagination-dark .page-item .page-link {
  color: #ffffff;
  border: 1px solid #393a3c;
  background: #000000;
}
.pagination-dark .page-item .page-link:hover {
  z-index: 2;
  color: #000000;
  text-decoration: none;
  background-color: transparent;
  border-color: #393a3c;
}
.pagination-dark .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.35);
}
.pagination-dark .page-item.active .page-link {
  z-index: 1;
  color: #000000;
  background-color: transparent;
  border-color: #000000;
}

.pagination-secondary .page-item .page-link {
  color: #ffffff;
  border: 1px solid #df1482;
  background-color: #ff2fa0;
}
.pagination-secondary .page-item .page-link:hover {
  z-index: 2;
  color: #ff2fa0;
  text-decoration: none;
  background-color: transparent;
  border-color: #df1482;
}
.pagination-secondary .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 47, 160, 0.51);
}
.pagination-secondary .page-item.active .page-link {
  z-index: 1;
  color: #ff2fa0;
  background-color: transparent;
  border-color: #df1482;
}

/* List Groups */
.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  color: #383838;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.list-group-item-action {
  color: #7934f3;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  color: #7934f3;
  background-color: rgba(255, 255, 255, 0.2);
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #7934f3;
  background-color: rgba(255, 255, 255, 0.2);
}

.dark-modal .modal-content {
  background: #00bfc8;
  background-image: -webkit-radial-gradient(circle 248px at center, rgb(0, 242, 96), rgb(5, 117, 230));
  background-image: radial-gradient(circle 248px at center, rgb(0, 242, 96), rgb(5, 117, 230));
  border: 0px solid rgba(255, 255, 255, 0.125);
  color: #fff;
}
.dark-modal .close {
  color: #fff;
}

.light-blue-backdrop {
  background-color: #5cb3fd;
}

.modal-content {
  background-color: #fff;
}

.popover-header {
  background-color: #fff;
}

.popover {
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
  border: none;
}

.popover-body {
  color: #000;
}

select.custom-select {
  margin: 0.25rem 0.25rem 0 0;
  width: auto;
}

.ngb-dp-header {
  border-bottom: 0;
  border-radius: 0.25rem 0.25rem 0 0;
  padding-top: 0.25rem;
  background-color: rgba(248, 249, 250, 0.1019607843) !important;
}

.ngb-dp-month-name {
  font-size: larger;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  background-color: rgba(248, 249, 250, 0.3568627451) !important;
}

ngb-datepicker {
  border: 1px solid #e7e7e7 !important;
}

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 1.9rem;
  width: 1.9rem;
  text-align: center;
  padding: 0.185rem 0.25rem;
  border-radius: 1rem;
  display: inline-block;
  width: 1.9rem;
}
.custom-day.focused {
  background-color: #e6e6e6;
}
.custom-day.range, .custom-day:hover {
  background-color: rgba(255, 255, 255, 0.5215686275);
  color: #fff;
}
.custom-day.faded {
  background-color: rgba(255, 255, 255, 0.2470588235);
}
.custom-day:hover, .custom-day.focused {
  background-color: rgba(255, 255, 255, 0.3960784314);
}

.weekend {
  background-color: #ffc16a;
  border-radius: 1rem;
  color: #fff;
}

.hidden {
  display: none;
}

.row {
  margin-right: -12.5px;
  margin-left: -12.5px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 12.5px;
  padding-left: 12.5px;
}

@media (min-width: 576px) {
  .card-deck {
    margin-right: -12.5px;
    margin-left: -12.5px;
  }
  .card-deck .card {
    margin-right: 12.5px;
    margin-left: 12.5px;
  }
}
.btn-social {
  width: 42px;
  height: 42px;
  font-size: 16px;
  line-height: 42px;
  display: inline-block;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.125);
  color: #fff;
  text-align: center;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}
.btn-social:hover, .btn-social:focus, .btn-social:active {
  outline: 0;
  color: #fff;
}

.btn-social-text {
  font-size: 0.72rem;
  text-transform: uppercase;
  padding: 10px 20px;
  font-weight: 600;
  border: 1px solid transparent;
  display: inline-block;
  background: #ddd;
  color: #fff;
  text-align: center;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}
.btn-social-text i {
  font-size: 0.95rem;
  padding-right: 4px;
  vertical-align: middle;
  display: table-cell;
}
.btn-social-text span {
  display: table-cell;
  vertical-align: middle;
}
.btn-social-text:hover {
  outline: 0;
  color: #fff;
}

.btn-social:focus, .btn-social:active {
  outline: 0;
  color: #fff;
}

.btn-social-circle {
  border-radius: 50%;
}

.btn-outline-facebook {
  color: #3b5998;
  background-color: #fff;
  border-color: #3b5998;
}
.btn-outline-facebook:hover, .btn-outline-facebook:focus {
  color: #fff;
  background-color: #3b5998;
}

.btn-outline-twitter {
  color: #55acee;
  background-color: #fff;
  border-color: #55acee;
}
.btn-outline-twitter:hover, .btn-outline-twitter:focus {
  background-color: #55acee;
  color: #fff;
}

.btn-outline-google-plus {
  color: #dd4b39;
  background-color: #fff;
  border-color: #dd4b39;
}
.btn-outline-google-plus:hover, .btn-outline-google-plus:focus {
  background-color: #dd4b39;
  color: #fff;
}

.btn-outline-linkedin {
  background-color: #fff;
  color: #0976b4;
  border-color: #0976b4;
}
.btn-outline-linkedin:hover, .btn-outline-linkedin:focus {
  background-color: #0976b4;
  color: #fff;
}

.btn-outline-pinterest {
  color: #cc2127;
  background-color: #fff;
  border-color: #cc2127;
}
.btn-outline-pinterest:hover, .btn-outline-pinterest:focus {
  background-color: #cc2127;
  color: #fff;
}

.btn-outline-youtube {
  color: #e52d27;
  background-color: #fff;
  border-color: #e52d27;
}
.btn-outline-youtube:hover, .btn-outline-youtube:focus {
  background-color: #e52d27;
  color: #fff;
}

.btn-outline-tumblr {
  color: #35465c;
  background-color: #fff;
  border-color: #35465c;
}
.btn-outline-tumblr:hover, .btn-outline-tumblr:focus {
  background-color: #35465c;
  color: #fff;
}

.btn-outline-github {
  color: #333333;
  background-color: #fff;
  border-color: #333333;
}
.btn-outline-github:hover {
  background-color: #333333;
  color: #fff;
}

.btn-github:focus {
  background-color: #333333;
  color: #fff;
}

.btn-outline-behance {
  color: #1769ff;
  background-color: #fff;
  border-color: #1769ff;
}
.btn-outline-behance:hover, .btn-outline-behance:focus {
  background-color: #1769ff;
  color: #fff;
}

.btn-outline-dribbble {
  color: #ea4c89;
  background-color: #fff;
  border-color: #ea4c89;
}
.btn-outline-dribbble:hover, .btn-outline-dribbble:focus {
  background-color: #ea4c89;
  color: #fff;
}

.btn-outline-skype {
  color: #00aff0;
  background-color: #fff;
  border-color: #00aff0;
}
.btn-outline-skype:hover, .btn-outline-skype:focus {
  background-color: #00aff0;
  color: #fff;
}

.btn-facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.btn-google-plus {
  background-color: #dd4b39;
  border-color: #dd4b39;
}

.btn-linkedin {
  background-color: #0976b4;
  border-color: #0976b4;
}

.btn-pinterest {
  background-color: #cc2127;
  border-color: #cc2127;
}

.btn-youtube {
  background-color: #e52d27;
  border-color: #e52d27;
}

.btn-tumblr {
  background-color: #35465c;
  border-color: #35465c;
}

.btn-github {
  background-color: #333333;
  border-color: #333333;
}

.btn-behance {
  background-color: #1769ff;
  border-color: #1769ff;
}

.btn-dribbble {
  background-color: #ea4c89;
  border-color: #ea4c89;
}

.btn-skype {
  background-color: #00aff0;
  border-color: #00aff0;
}

#sidebar-wrapper {
  background: #ffffff;
  position: fixed;
  top: 60px;
  bottom: 0px;
  left: 0px;
  z-index: 1000;
  overflow: hidden;
  width: 250px;
  height: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(57, 98, 254, 0.02), 0 3px 6px rgba(117, 108, 254, 0.12);
}

.sidebar .brand-logo {
  width: 250px;
  height: 60px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1111;
  background-color: rgb(249, 249, 249);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sidebar .logo-text {
  color: rgba(0, 0, 0, 0.84);
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0;
  line-height: 0;
  margin-left: 10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sidebar .logo-icon {
  width: 35px;
}
.sidebar .close-btn {
  margin-left: auto !important;
  font-size: 20px;
  display: none;
  cursor: pointer;
}
.sidebar .navigation i {
  font-size: 22px;
  line-height: 1;
}

.sidebar .sidebar-content {
  position: relative;
  height: calc(100% - 50px);
  overflow: auto;
  z-index: 4;
}
.sidebar li:first-child,
.sidebar li + li {
  margin-top: 5px;
}
.sidebar li:last-child {
  margin-bottom: 5px;
}
.sidebar .navigation {
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.sidebar .navigation li {
  position: relative;
  display: block;
}
.sidebar .navigation li.sub > a {
  margin-bottom: 0;
}
.sidebar .navigation li.sub > a:after {
  position: absolute;
  content: "";
  width: 0.5em;
  height: 0.5em;
  border-style: solid;
  border-width: 1.2px 0 0 1.2px;
  border-color: initial;
  right: 15px;
  transform: rotate(-45deg) translateY(-50%);
  transform-origin: top;
  top: 20.5px;
  transition: all 0.3s ease-out;
}
.sidebar .navigation li.open > a {
  color: #7934f3;
  text-decoration: none;
  background: rgba(121, 52, 243, 0.15);
}
.sidebar .navigation li.open > a:after {
  transform: rotate(-135deg) translateY(-50%);
}
.sidebar .navigation li > a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px 15px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  outline-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: 0.5px;
  border: 1px solid rgba(0, 0, 0, 0);
  transition: all 0.3s ease-out;
}
.sidebar .navigation li > a:before {
  content: "";
  display: block;
  z-index: -1;
  position: absolute;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
}
.sidebar .navigation li:not(.open) > ul {
  display: none;
}
.sidebar .navigation li:hover > a {
  color: #7934f3;
  text-decoration: none;
  background: rgba(121, 52, 243, 0.15);
}
.sidebar .navigation li.active > a {
  color: #7934f3;
  text-decoration: none;
  background: rgba(121, 52, 243, 0.15);
}
.sidebar .navigation li.active > a:before {
  left: 0;
}
.sidebar .navigation li ul {
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.sidebar .navigation li ul li a {
  padding: 7px 15px 7px 15px;
  border: 0;
}
.sidebar .navigation li ul li:hover > a, .sidebar .navigation li ul li.active > a {
  color: #7934f3;
  text-decoration: none;
  background: rgba(121, 52, 243, 0.15);
}
.sidebar .navigation .menu-title {
  margin-left: 12px;
  font-size: 15px;
  font-weight: 400;
  opacity: 1;
  height: auto;
  -webkit-transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.sidebar .navigation .sub-menu-title {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  opacity: 1;
  height: auto;
  -webkit-transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.sidebar .navigation .badge.badge-pill {
  position: absolute;
  right: 28px;
  padding: 4px 8px;
  font-weight: 300;
  -webkit-transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.sidebar .navigation li ul li i {
  font-size: 14px;
  margin-right: 12px;
}
.sidebar .navigation li li.sub > a:after {
  top: 16.5px;
}

@media screen and (min-width: 1025px) {
  #wrapper.nav-collapsed:not(.sidebar-hovered) #sidebar-wrapper {
    width: 70px;
  }
  #wrapper.nav-collapsed .content-wrapper,
#wrapper.nav-collapsed .toggle-menu {
    margin-left: 70px;
  }
  #wrapper.nav-collapsed:not(.sidebar-hovered) #sidebar-wrapper .navigation a {
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  #wrapper.nav-collapsed:not(.sidebar-hovered) .brand-logo {
    width: 70px;
    justify-content: center;
  }
  #wrapper.nav-collapsed:not(.sidebar-hovered) .logo-text {
    display: none;
  }
  #wrapper.nav-collapsed:not(.sidebar-hovered) #sidebar-wrapper .navigation .menu-title,
#wrapper.nav-collapsed:not(.sidebar-hovered) #sidebar-wrapper .navigation .badge {
    display: none;
  }
  #wrapper.nav-collapsed:not(.sidebar-hovered) #sidebar-wrapper .navigation li ul, #wrapper.nav-collapsed:not(.sidebar-hovered) #sidebar-wrapper .navigation li.menu-label {
    display: none;
  }
  #wrapper.nav-collapsed:not(.sidebar-hovered) #sidebar-wrapper .navigation .sub > a:after {
    display: none;
  }
}
#sidebar-wrapper .navigation ul ul a {
  padding: 8px 15px 8px 30px;
}

/*----------------right-sidebar----------------*/
.right-sidebar {
  width: 260px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  overflow: scroll;
  overflow-x: hidden;
  top: 0;
  right: -300px;
  z-index: 999;
  text-align: center;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.switcher-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #000;
  text-align: center;
  font-size: 22px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  position: fixed;
  right: 0;
  top: 35%;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.right-sidebar.right-toggled {
  right: 0px;
}
.right-sidebar.right-toggled .switcher-icon {
  right: 260px;
}

.bg-theme {
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  transition: background 0.3s;
}

.switcher {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-left: 20px;
}
.switcher li {
  float: left;
  width: 85px;
  height: 75px;
  margin: 0 15px 15px 0px;
  border-radius: 4px;
  border: 0px solid black;
}

#header1,
#theme1 {
  background-image: linear-gradient(45deg, #0a151f, #232e3a);
  background-size: 100% 100%;
  background-position: center;
  transition: background 0.3s;
}

#header2,
#theme2 {
  background-image: linear-gradient(45deg, #0312c4, #0350c5);
  background-size: 100% 100%;
  background-position: center;
  transition: background 0.3s;
}

#header3,
#theme3 {
  background-image: linear-gradient(45deg, #d84315, #f57c00);
  background-size: 100% 100%;
  background-position: center;
  transition: background 0.3s;
}

#header4,
#theme4 {
  background-image: linear-gradient(45deg, #4a148c, #6a1b9a);
  background-size: 100% 100%;
  background-position: center;
  transition: background 0.3s;
}

#header5,
#theme5 {
  background-image: linear-gradient(45deg, #c70350, #bb0a46);
  background-size: 100% 100%;
  background-position: center;
  transition: background 0.3s;
}

#header6,
#theme6 {
  background-image: linear-gradient(45deg, #0c675e, #069e90);
  background-size: 100% 100%;
  background-position: center;
  transition: background 0.3s;
}

.bg-theme1 {
  background-image: linear-gradient(45deg, #0a151f, #232e3a) !important;
}

.bg-theme2 {
  background: linear-gradient(45deg, #0312c4, #0350c5) !important;
}

.bg-theme3 {
  background-image: linear-gradient(45deg, #d84315, #f57c00) !important;
}

.bg-theme4 {
  background-image: linear-gradient(45deg, #4a148c, #6a1b9a) !important;
}

.bg-theme5 {
  background-image: linear-gradient(45deg, #c70350, #bb0a46) !important;
}

.bg-theme6 {
  background-image: linear-gradient(45deg, #0c675e, #069e90) !important;
}

/* sidebar switcher */
#sidebar-wrapper.bg-theme .brand-logo .logo-text {
  color: #ffffff;
}

#sidebar-wrapper.bg-theme .brand-logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

#sidebar-wrapper.bg-theme .user-details {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

#sidebar-wrapper.bg-theme .user-details .media .media-body .side-user-name {
  color: #ffffff;
}

#sidebar-wrapper.bg-theme .user-details div.user-pointer:after {
  color: #ffffff;
}

#sidebar-wrapper.bg-theme .user-setting-menu > li > a {
  color: rgba(255, 255, 255, 0.22);
}

#sidebar-wrapper.bg-theme .user-setting-menu > li > a:hover {
  color: rgb(255, 255, 255);
}

#sidebar-wrapper.bg-theme .sidebar-header {
  color: rgba(255, 255, 255, 0.5);
}

#sidebar-wrapper.bg-theme .navigation > li > a {
  color: rgba(255, 255, 255, 0.5);
}

#sidebar-wrapper.bg-theme .navigation li ul > li > a {
  color: rgba(255, 255, 255, 0.5);
}

#sidebar-wrapper.bg-theme .navigation li.open > a {
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}

#sidebar-wrapper.bg-theme .navigation li:hover > a {
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}

#sidebar-wrapper.bg-theme .navigation li.active > a {
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}

#sidebar-wrapper.bg-theme .navigation li ul li:hover > a,
#sidebar-wrapper.bg-theme .navigation li ul li.active > a {
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}

/* topbar switcher */
.color-header .toggle-menu i {
  color: #ffffff;
}

.color-header .right-nav-link a.nav-link {
  color: #ffffff;
}

.color-header .search-bar input {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.color-header .search-bar input:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.255);
}

/* scrollbar */
#sidebar-wrapper.bg-theme .simplebar-scrollbar:before {
  background: rgba(255, 255, 255, 0.4);
}

.skill-img {
  height: 35px;
}

.page-title {
  font-size: 1.1875rem;
  line-height: 20px;
}

.breadcrumb {
  padding: 0;
  background-color: transparent;
}

.sidebar-menu li a i:first-child {
  margin-right: 10px;
  font-size: 18px;
}
.sidebar-menu li a i:last-child {
  margin-right: 10px;
  font-size: 12px;
}

.lightbox-thumb {
  margin-bottom: 15px;
  width: 100%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.mail-img {
  width: 50px;
}

.row.row-group > div {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.row.row-group > div:last-child {
  border-right: none;
}

code {
  font-size: 87.5%;
  color: #4683fd;
  word-break: break-word;
}

.breadcrumb-item.active {
  color: #615f5f;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #615f5f;
}

.fonticon-container > .fonticon-wrap {
  float: left;
  width: 40px;
  height: 40px;
  line-height: 1.8rem;
  text-align: center;
  border-radius: 0.1875rem;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
}
.fonticon-container > .fonticon-wrap > i {
  font-size: 1.5rem;
}
.fonticon-container > .fonticon-wrap > i.fa {
  font-size: 1.5rem !important;
}
.fonticon-container > .fonticon-wrap.youtube {
  width: 100px;
}
.fonticon-container:hover i {
  color: #dc3545;
}
.fonticon-container > .fonticon-classname,
.fonticon-container > .fonticon-unit {
  display: block;
  font-size: 0.95rem;
  line-height: 1;
}

.user-lock {
  height: 150px !important;
}

.user-lock-img {
  width: 130px;
  margin: auto;
}
.user-lock-img img {
  width: 100%;
  border-radius: 50%;
  margin-top: 80px;
  border: 4px solid #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.payment-icons img {
  width: 100px;
}

.icon a {
  display: block;
  color: #656464;
  padding: 8px;
  line-height: 32px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 2px;
}
.icon a:hover {
  background: #7934f3;
  color: #fff;
  text-decoration: none;
}
.icon a i {
  padding-right: 10px;
}

.icon-section {
  clear: both;
  overflow: hidden;
}

.icon-container {
  width: 240px;
  padding: 0.7em 0;
  float: left;
  position: relative;
  text-align: left;
}
.icon-container [class^=ti-],
.icon-container [class*=" ti-"] {
  color: #656464;
  position: absolute;
  margin-top: 3px;
  transition: 0.3s;
}
.icon-container:hover [class^=ti-],
.icon-container:hover [class*=" ti-"] {
  font-size: 2.2em;
  margin-top: -5px;
}
.icon-container:hover .icon-name {
  color: #656464;
}

.icon-name {
  color: #656464;
  margin-left: 35px;
  transition: 0.3s;
}

.icon-container:hover .icon-name {
  margin-left: 45px;
}

.preview {
  padding: 15px 0;
  position: relative;
}

.icon-preview-box div:hover {
  background: rgba(255, 255, 255, 0.3);
}

.show-code {
  color: #656464;
}

.icons {
  font-size: 15px;
  padding-right: 7px;
}

.name {
  font-size: 15px;
}

.preview a {
  padding: 15px;
}
.preview a:hover {
  padding: 15px;
  text-decoration: none;
}
.preview a i {
  margin-right: 10px;
  font-size: 18px;
}

.flag-icon-list div {
  cursor: pointer;
  line-height: 60px;
  white-space: nowrap;
  color: #656464;
}
.flag-icon-list i {
  -webkit-transition: all 0.2s;
  -webkit-transition: font-size 0.2s;
  display: inline-block;
  font-size: 18px;
  margin: 0 15px 0 10px;
  text-align: left;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -o-transition: font-size 0.2s;
  transition: font-size 0.2s;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.product-div {
  width: auto;
  height: auto;
  background: rgba(255, 255, 255, 0);
  padding: 5px;
  text-align: center;
  border: 1px solid rgba(66, 59, 116, 0.15);
  border-radius: 4px;
}
.product-div img {
  width: 40px;
  height: 40px;
}

.product-img {
  height: 32px;
}

.customer-img {
  height: 55px;
}

.w_chart {
  position: relative;
  display: inline-block;
  width: 65px !important;
  height: 65px !important;
  text-align: center;
}

.w_percent {
  display: inline-block;
  line-height: 65px !important;
  z-index: 2;
  color: #353434;
}

.fleet-status {
  position: relative;
  display: inline-block;
  width: 90px !important;
  height: 90px !important;
  text-align: center;
}

.fleet-status canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 90px !important;
  height: 90px !important;
}

.fleet-status-percent {
  display: inline-block;
  line-height: 90px !important;
  z-index: 2;
  color: #ffffff;
}

.fleet-status-percent:after {
  content: "%";
  margin-left: 0.1em;
  font-size: 0.8em;
  color: #ffffff;
}

.dash-array-chart-box {
  width: 105px;
  height: 90px;
  position: relative;
  top: -15px;
  right: 30px;
}

.font-33 {
  font-size: 33px;
}

.chart-container {
  position: relative;
  height: 330px;
}

.chart-container-1 {
  position: relative;
  height: 260px;
}

.chart-container-2 {
  position: relative;
  height: 188px;
}

.chart-container-3 {
  position: relative;
  height: 188px;
}

.chart-container-4 {
  position: relative;
  height: 162px;
}

.chart-container-5 {
  position: relative;
  height: 110px;
}

.chart-container-6 {
  position: relative;
  height: 205px;
}

.chart-container-7 {
  position: relative;
  height: 60px;
}

.chart-container-8 {
  position: relative;
  height: 260px;
}

.chart-container-9 {
  position: relative;
  height: 280px;
}

.chart-container-10 {
  position: relative;
  height: 300px;
  top: 20px;
}

.chart-container-11 {
  position: relative;
  height: 280px;
}

.chart-container-12 {
  position: relative;
  height: 160px;
}

.chart-container-13 {
  position: relative;
  height: 240px;
}

.chart-container-14 {
  position: relative;
  height: 40px;
}

.chart-container-15 {
  position: relative;
  height: 180px;
}

.height-240 {
  height: 240px;
  width: 100%;
  display: block;
}

.nav-pills,
.nav-tabs {
  margin: 0 0 25px;
}

.icon-box {
  width: 52px;
  height: 52px;
  line-height: 52px;
  font-size: 25px;
  color: #383838;
  margin: auto;
  border: 0px solid #fff;
  border-radius: 50%;
  text-align: center;
}

.icon-box i {
  color: #000;
  font-size: 25px;
  position: relative;
}

.demo-heading {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 0.25rem;
  color: #615f5f;
}

.badge-top {
  position: absolute;
  top: 15px;
}

.users {
  width: 40px;
  margin-right: -16px;
}

.height-100v {
  height: 100vh;
}

/* User Details */
.user-profile img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.user-details .media .avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.user-details .media .media-body .user-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.84);
  margin-bottom: 2px;
}
.user-details .media .media-body .user-subtitle {
  font-size: 13px;
  color: #232323;
  margin-bottom: 0;
}

/*Cards */
.card {
  margin-bottom: 25px;
  box-shadow: 0 5px 15px rgba(57, 98, 254, 0.02), 0 3px 6px rgba(117, 108, 254, 0.12);
  border: none;
  background-color: #ffffff;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.84);
}

.card-title {
  margin-bottom: 0.75rem;
  font-weight: 600;
  font-size: 16px;
  color: #353434;
}

.card-action {
  float: right;
}
.card-action a i {
  color: #353434;
  border-radius: 50%;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.card-deck {
  margin-bottom: 25px;
}

@media (min-width: 576px) {
  .card-deck {
    margin-right: -12.5px;
    margin-left: -12.5px;
  }
  .card-deck .card {
    margin-right: 12.5px;
    margin-left: 12.5px;
  }
}
.card-group {
  box-shadow: 0 5px 15px rgba(57, 98, 254, 0.02), 0 3px 6px rgba(117, 108, 254, 0.12);
  margin-bottom: 25px;
}
.card-group .card {
  box-shadow: none;
}

.bubble {
  position: relative;
  overflow: hidden;
}
.bubble .card-block:before, .bubble .card-block:after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.22);
  right: -40px;
  border-radius: 50%;
}
.bubble .card-block:before {
  width: 100px;
  height: 100px;
  bottom: -40px;
}
.bubble .card-block:after {
  width: 80px;
  height: 80px;
  bottom: 40px;
}

/*Profile card 2*/
.profile-card-2 .card-img-block {
  float: left;
  width: 100%;
  height: 150px;
  overflow: hidden;
}
.profile-card-2 .card-body {
  position: relative;
}
.profile-card-2 .profile {
  border-radius: 50%;
  position: absolute;
  top: -42px;
  left: 15%;
  max-width: 75px;
  border: 3px solid rgb(255, 255, 255);
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}
.profile-card-2 h5 {
  font-weight: 600;
}
.profile-card-2 .card-text {
  font-weight: 300;
  font-size: 15px;
}
.profile-card-2 .icon-block {
  float: left;
  width: 100%;
}
.profile-card-2 .icon-block a {
  text-decoration: none;
}
.profile-card-2 i {
  display: inline-block;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  margin: 0 5px;
}

.profile-card img {
  border-radius: 50%;
  padding: 4px;
  background-color: #fff;
  border: 1px solid #c4c1da;
}

.profile-cover {
  position: relative;
  width: 100%;
  height: 125px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-size: cover;
  background-position: center;
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
}

.profile-cover-img1 {
  background-image: url(./../../../images/gallery/32.jpg);
}

.profile-cover-img2 {
  background-image: url(./../../../images/gallery/31.jpg);
}

.profile-cover-img3 {
  background-image: url(./../../../images/gallery/27.jpg);
}

.profile-cover-img4 {
  background-image: url(./../../../images/gallery/33.jpg);
}

.border-angle {
  border-top-left-radius: 60px !important;
  border-bottom-right-radius: 60px !important;
}

.card-img-1 {
  background-image: url(./../../../images/gallery/38.jpg);
  background-size: cover;
  background-position: center;
}

.card-img-2 {
  background-image: url(./../../../images/gallery/40.jpg);
  background-size: cover;
  background-position: center;
}

.card-img-3 {
  background-image: url(./../../../images/gallery/41.jpg);
  background-size: cover;
  background-position: center;
}

.overlay-div1 {
  background-image: url(https://images.pexels.com/photos/1391580/pexels-photo-1391580.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  width: 100%;
  height: 520px;
  border-radius: 0.25rem;
  background-size: cover;
  background-position: center;
}

.overlay-div2 {
  background-image: url(https://images.pexels.com/photos/2175346/pexels-photo-2175346.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  width: 100%;
  height: 520px;
  border-radius: 0.25rem;
  background-size: cover;
  background-position: center;
}

.overlay-div3 {
  background-image: url(https://images.pexels.com/photos/2344096/pexels-photo-2344096.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  width: 100%;
  height: 520px;
  border-radius: 0.25rem;
  background-size: cover;
  background-position: center;
}

/* Background Colors */
.bg-primary {
  background-color: #7934f3 !important;
}

.bg-success {
  background-color: #04b962 !important;
}

.bg-info {
  background-color: #14b6ff !important;
}

.bg-secondary {
  background-color: #94614f !important;
}

.bg-danger {
  background-color: #f43643 !important;
}

.bg-dark {
  background-color: #0a151f !important;
}

.bg-dark-light {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.bg-warning {
  background-color: #ff8800 !important;
}

.bg-light {
  background-color: rgba(255, 255, 255, 0.125) !important;
}

.bg-contrast {
  background: rgba(255, 255, 255, 0.3) !important;
}

.bg-body {
  background: rgb(247, 247, 255) !important;
}

.bg-primary-light1 {
  background-color: rgba(144, 79, 254, 0.22);
}

.bg-primary-light2 {
  background-color: rgba(144, 79, 254, 0.42);
}

.gradient-primary-light {
  background-color: #8f50ff;
  background-image: radial-gradient(circle 30px at center, #f8aeff, #8f50ff) !important;
}

.bg-success-light1 {
  background-color: rgba(8, 165, 14, 0.22);
}

.bg-success-light2 {
  background-color: rgba(8, 165, 14, 0.42);
}

.gradient-success-light {
  background-color: #0aa60f;
  background-image: radial-gradient(circle 30px at center, rgb(202, 219, 52), rgb(10, 166, 15)) !important;
}

.bg-info-light1 {
  background-color: rgba(0, 129, 255, 0.22);
}

.bg-info-light2 {
  background-color: rgba(0, 129, 255, 0.42);
}

.gradient-info-light {
  background-color: #0074ff;
  background-image: radial-gradient(circle 30px at center, rgb(113, 222, 253), rgb(0, 116, 255)) !important;
}

.bg-danger-light1 {
  background-color: rgba(245, 13, 85, 0.22);
}

.bg-danger-light2 {
  background-color: rgba(245, 13, 85, 0.42);
}

.gradient-danger-light {
  background-color: #f50d55;
  background-image: radial-gradient(circle 30px at center, rgb(251, 208, 206), #f50d55) !important;
}

.bg-warning-light1 {
  background-color: rgba(247, 151, 30, 0.22);
}

.bg-warning-light2 {
  background-color: rgba(247, 152, 30, 0.42);
}

.gradient-warning-light {
  background-color: #f7981e;
  background-image: radial-gradient(circle 30px at center, rgb(253, 239, 176), #f7981e) !important;
}

.bg-secondary-light1 {
  background-color: rgba(247, 3, 254, 0.22);
}

.bg-secondary-light2 {
  background-color: rgba(247, 3, 254, 0.42);
}

.gradient-secondary-light {
  background-color: #f703fe;
  background-image: radial-gradient(circle 30px at center, rgb(254, 219, 255), #f703fe) !important;
}

.bg-dark-light1 {
  background-color: rgba(0, 0, 0, 0.22);
}

.bg-dark-light2 {
  background-color: rgba(0, 0, 0, 0.42);
}

.gradient-dark-light {
  background-color: #000000;
  background-image: radial-gradient(circle 30px at center, rgb(173, 172, 172), #000000) !important;
}

.bg-white-light1 {
  background-color: rgba(255, 255, 255, 0.22);
}

.bg-white-light2 {
  background-color: rgba(255, 255, 255, 0.42);
}

.gradient-white-light {
  background-color: #ffffff;
  background-image: radial-gradient(circle 30px at center, rgb(255, 255, 255), rgba(0, 0, 0, 0.78)) !important;
}

.bg-facebook {
  background-color: #3b5998 !important;
}

.bg-twitter {
  background-color: #55acee !important;
}

.bg-google-plus {
  background-color: #dd4b39 !important;
}

.bg-linkedin {
  background-color: #0976b4 !important;
}

.bg-pinterest {
  background-color: #cc2127 !important;
}

.bg-youtube {
  background-color: #e52d27 !important;
}

.bg-tumblr {
  background-color: #35465c !important;
}

.bg-github {
  background-color: #333333 !important;
}

.bg-behance {
  background-color: #1769ff !important;
}

.bg-dribbble {
  background-color: #ea4c89 !important;
}

.bg-skype {
  background-color: #00aff0 !important;
}

.gradient-bloody {
  background: #f54ea2;
  background: -webkit-linear-gradient(45deg, #f54ea2, #ff7676) !important;
  background: linear-gradient(45deg, #f54ea2, #ff7676) !important;
}

.gradient-scooter {
  background: #4facfe;
  background: -webkit-linear-gradient(45deg, #4facfe 0%, #00f2fe 100%) !important;
  background: linear-gradient(45deg, #4facfe 0%, #00f2fe 100%) !important;
}

.gradient-blooker {
  background: #fa709a;
  background: -webkit-linear-gradient(45deg, #fa709a 0%, #fee140 100%) !important;
  background: linear-gradient(45deg, #fa709a 0%, #fee140 100%) !important;
}

.gradient-quepal {
  background: #11998e;
  background: -webkit-linear-gradient(45deg, #11998e, #38ef7d) !important;
  background: linear-gradient(45deg, #11998e, #38ef7d) !important;
}

.gradient-purpink {
  background: #7f00ff;
  background: -webkit-linear-gradient(45deg, #7f00ff, #e100ff) !important;
  background: linear-gradient(45deg, #7f00ff, #e100ff) !important;
}

.gradient-ibiza {
  background: #ee0979;
  background: -webkit-linear-gradient(45deg, #ee0979, #ff6a00) !important;
  background: linear-gradient(45deg, #ee0979, #ff6a00) !important;
}

.gradient-deepblue {
  background: #6a11cb;
  background: -webkit-linear-gradient(45deg, #6a11cb, #2575fc) !important;
  background: linear-gradient(45deg, #6a11cb, #2575fc) !important;
}

.gradient-branding {
  background: #6a11cb;
  background: -webkit-linear-gradient(180deg, #2af598, #009efd) !important;
  background: linear-gradient(180deg, #2af598, #009efd) !important;
}

.gradient-ohhappiness {
  background: #00b09b;
  background: -webkit-linear-gradient(45deg, #00b09b, #96c93d) !important;
  background: linear-gradient(45deg, #00b09b, #96c93d) !important;
}

.gradient-dusk {
  background: #2c3e50;
  background: -webkit-linear-gradient(45deg, #2c3e50, #fd746c) !important;
  background: linear-gradient(45deg, #2c3e50, #fd746c) !important;
}

.gradient-meridian {
  background: #283c86;
  background: -webkit-linear-gradient(45deg, #283c86, #45a247) !important;
  background: linear-gradient(45deg, #283c86, #45a247) !important;
}

.gradient-violet {
  background: #4776e6;
  background: -webkit-linear-gradient(45deg, #4776e6, #8e54e9) !important;
  background: linear-gradient(45deg, #4776e6, #8e54e9) !important;
}

.gradient-titanium {
  background: #283048;
  background: -webkit-linear-gradient(45deg, #283048, #859398) !important;
  background: linear-gradient(45deg, #283048, #859398) !important;
}

.gradient-shifter {
  background: #bc4e9c;
  background: -webkit-linear-gradient(45deg, #bc4e9c, #f80759) !important;
  background: linear-gradient(45deg, #bc4e9c, #f80759) !important;
}

.gradient-army {
  background: #414d0b;
  background: -webkit-linear-gradient(45deg, #414d0b, #727a17) !important;
  background: linear-gradient(45deg, #414d0b, #727a17) !important;
}

.gradient-orange {
  background: #fc4a1a;
  background: -webkit-linear-gradient(45deg, #fc4a1a, #f7b733) !important;
  background: linear-gradient(45deg, #fc4a1a, #f7b733) !important;
}

.gradient-yoda {
  background: #ff0099;
  background: -webkit-linear-gradient(45deg, #ff0099, #493240) !important;
  background: linear-gradient(45deg, #ff0099, #493240) !important;
}

.gradient-royal {
  background: #141e30;
  background: -webkit-linear-gradient(45deg, #141e30, #2c527d) !important;
  background: linear-gradient(45deg, #141e30, #2c527d) !important;
}

.gradient-knight {
  background: #ba8b02;
  background: -webkit-linear-gradient(45deg, #ba8b02, #181818) !important;
  background: linear-gradient(45deg, #ba8b02, #181818) !important;
}

.gradient-forest {
  background: #000428;
  background: -webkit-linear-gradient(45deg, #000428, #004e92) !important;
  background: linear-gradient(45deg, #000428, #004e92) !important;
}

.gradient-redmist {
  background: #353434;
  background: -webkit-linear-gradient(45deg, #353434, #e74c3c) !important;
  background: linear-gradient(45deg, #353434, #e74c3c) !important;
}

.gradient-steelgray {
  background: #1f1c2c;
  background: -webkit-linear-gradient(45deg, #1f1c2c, #928dab) !important;
  background: linear-gradient(45deg, #1f1c2c, #928dab) !important;
}

.gradient-influenza {
  background: #c04848;
  background: -webkit-linear-gradient(45deg, #c04848, #480048) !important;
  background: linear-gradient(45deg, #c04848, #480048) !important;
}

.gradient-sylvia {
  background: #ff4b1f;
  background: -webkit-linear-gradient(45deg, #ff4b1f, #ff9068) !important;
  background: linear-gradient(45deg, #ff4b1f, #ff9068) !important;
}

.gradient-jshine {
  background: #12c2e9;
  background: -webkit-linear-gradient(45deg, #12c2e9, #c471ed, #f64f59) !important;
  background: linear-gradient(45deg, #12c2e9, #c471ed, #f64f59) !important;
}

.gradient-lovekiss {
  background: #ff0844;
  background: -webkit-linear-gradient(45deg, #ff0844, #ffb199) !important;
  background: linear-gradient(45deg, #ff0844 0%, #ffb199 100%) !important;
}

.gradient-hossein {
  background: #f7ff00;
  background: -webkit-linear-gradient(45deg, #f7ff00, #db36a4) !important;
  background: linear-gradient(45deg, #f7ff00, #db36a4) !important;
}

.gradient-primary {
  background: #8f50ff;
  background: -webkit-linear-gradient(180deg, #8f50ff, #d13adf) !important;
  background: linear-gradient(180deg, #8f50ff, #d13adf) !important;
}

.gradient-info {
  background: #00c8ff;
  background: -webkit-linear-gradient(180deg, #00c8ff, #0072ff) !important;
  background: linear-gradient(180deg, #00c8ff, #0072ff) !important;
}

.gradient-danger {
  background: #ff5447;
  background: -webkit-linear-gradient(180deg, #ff5447, #f1076f) !important;
  background: linear-gradient(180deg, #ff5447, #f1076f) !important;
}

.gradient-success {
  background: #08a50e;
  background: -webkit-linear-gradient(180deg, #08a50e, #cddc35) !important;
  background: linear-gradient(180deg, #08a50e, #cddc35) !important;
}

.gradient-warning {
  background: #f7971e;
  background: -webkit-linear-gradient(180deg, #f7971e, #ffd200) !important;
  background: linear-gradient(180deg, #f7971e, #ffd200) !important;
}

.gradient-secondary {
  background: #fc00ff;
  background: -webkit-linear-gradient(180deg, #fc00ff, #00dbde) !important;
  background: linear-gradient(180deg, #fc00ff, #00dbde) !important;
}

.gradient-esinto {
  background: #0a0707;
  background-image: -webkit-linear-gradient(45deg, #0a0707 50%, #ff9800 50%);
  background-image: linear-gradient(45deg, #0a0707 50%, #ff9800 50%);
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-dunada {
  background: #0a0707;
  background-image: -webkit-linear-gradient(45deg, #000000 50%, #0085ff 50%);
  background-image: linear-gradient(45deg, #000000 50%, #0085ff 50%);
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-linga {
  background: #0a0707;
  background-image: -webkit-linear-gradient(45deg, #000000 50%, #7020ff 50%);
  background-image: linear-gradient(45deg, #000000 50%, #7020ff 50%);
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-blkw {
  background: #0a0707;
  background-image: -webkit-linear-gradient(45deg, #000000 50%, #fff 50%);
  background-image: linear-gradient(45deg, #000000 50%, #fff 50%);
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-rainbow {
  background: #00bfc8;
  background-image: -webkit-radial-gradient(circle 248px at center, rgb(0, 242, 96), rgb(5, 117, 230));
  background-image: radial-gradient(circle 248px at center, rgb(0, 242, 96), rgb(5, 117, 230));
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-smile {
  background: #16d9e3;
  background-image: -webkit-radial-gradient(circle 248px at center, #16d9e3 0%, #30c7ec 47%, #46aef7 100%);
  background-image: radial-gradient(circle 248px at center, #609cf7 0%, #0741cc 47%, #3f51b5 100%);
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-veer {
  background: #799f0c;
  background-image: -webkit-radial-gradient(circle 248px at center, rgb(255, 224, 0), rgb(121, 159, 12));
  background-image: radial-gradient(circle 248px at center, rgb(255, 224, 0), rgb(121, 159, 12));
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-worldcup {
  background: #f14793;
  background-image: -webkit-radial-gradient(circle 248px at center, #f14793, #5204ce);
  background-image: radial-gradient(circle 248px at center, #f14793, #5204ce);
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-pinki {
  background: #f1ea07;
  background-image: -webkit-radial-gradient(circle 248px at center, #f1ea07, #f10707);
  background-image: radial-gradient(circle 248px at center, #f1ea07, #f10707);
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-multiple-colors {
  background-image: linear-gradient(567deg, rgba(165, 42, 4, 0.89), rgba(113, 102, 8, 0.89), rgba(13, 95, 16, 0.93), rgba(4, 79, 88, 0.94), rgba(19, 56, 86, 0.9), rgba(24, 32, 78, 0.94), rgba(100, 8, 115, 0.95));
  background-size: 100% 100%;
  background-position: center;
  transition: background 0.3s;
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.texture-info {
  background-image: url(./../../../images/textures/1.png);
  background-size: cover;
  background-position: center;
}

.texture-danger {
  background-image: url(./../../../images/textures/2.png);
  background-size: cover;
  background-position: center;
}

.texture-success {
  background-image: url(./../../../images/textures/3.png);
  background-size: cover;
  background-position: center;
}

.texture-warning {
  background-image: url(./../../../images/textures/4.png);
  background-size: cover;
  background-position: center;
}

.texture-primary {
  background-image: url(./../../../images/textures/5.png);
  background-size: cover;
  background-position: center;
}

.texture-wave-a {
  background-image: url(./../../../images/textures/texture-a.png);
  background-size: cover;
  background-position: center;
}

.texture-wave-b {
  background-image: url(./../../../images/textures/texture-b.png);
  background-size: cover;
  background-position: center;
}

.texture-wave-c {
  background-image: url(./../../../images/textures/texture-c.png);
  background-size: cover;
  background-position: center;
}

.texture-wave-d {
  background-image: url(./../../../images/textures/texture-d.png);
  background-size: cover;
  background-position: center;
}

.texture-wave-e {
  background-image: url(./../../../images/textures/texture-e.png);
  background-size: cover;
  background-position: center;
}

.texture-wave-f {
  background-image: url(./../../../images/textures/texture-f.png);
  background-size: cover;
  background-position: center;
}

/* Borders */
.border-left-xs {
  border-left: 1px solid !important;
}

.border-right-xs {
  border-right: 1px solid !important;
}

.border-top-xs {
  border-top: 1px solid !important;
}

.border-bottom-xs {
  border-bottom: 1px solid !important;
}

.border-left-sm {
  border-left: 2px solid !important;
}

.border-right-sm {
  border-right: 2px solid !important;
}

.border-top-sm {
  border-top: 2px solid !important;
}

.border-bottom-sm {
  border-bottom: 2px solid !important;
}

.border-left-md {
  border-left: 3px solid !important;
}

.border-right-md {
  border-right: 3px solid !important;
}

.border-top-md {
  border-top: 3px solid !important;
}

.border-bottom-md {
  border-bottom: 3px solid !important;
}

.border-left-lg {
  border-left: 4px solid !important;
}

.border-right-lg {
  border-right: 4px solid !important;
}

.border-top-lg {
  border-top: 4px solid !important;
}

.border-bottom-lg {
  border-bottom: 4px solid !important;
}

.border-sm {
  border: 3px solid;
}

.border-primary {
  border-color: #7934f3 !important;
}

.border-success {
  border-color: #04b962 !important;
}

.border-info {
  border-color: #14b6ff !important;
}

.border-secondary {
  border-color: #94614f !important;
}

.border-secondary-light {
  border-color: #33444a !important;
}

.border-danger {
  border-color: #f43643 !important;
}

.border-dark {
  border-color: #353434 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-white-2 {
  border-color: rgba(255, 255, 255, 0.33) !important;
}

.border-white-3 {
  border-color: rgba(255, 255, 255, 0.12) !important;
}

.border-warning {
  border-color: #ff8800 !important;
}

.border-light {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.border-light-2 {
  border-color: rgba(0, 0, 0, 0.08) !important;
}

.border-light-3 {
  border-color: rgba(0, 0, 0, 0.05) !important;
}

/* Text Colors */
.text-primary {
  color: #7934f3 !important;
}

.text-success {
  color: #04b962 !important;
}

.text-info {
  color: #14b6ff !important;
}

.text-secondary {
  color: #94614f !important;
}

.text-danger {
  color: #f43643 !important;
}

.text-dark {
  color: #0a151f !important;
}

.text-warning {
  color: #ff8800 !important;
}

.text-light {
  color: rgba(255, 255, 255, 0.125) !important;
}

.text-light-1 {
  color: rgba(255, 255, 255, 0.7) !important;
}

.text-light-2 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-light-3 {
  color: rgba(255, 255, 255, 0.2) !important;
}

.text-gradient-primary {
  background: linear-gradient(180deg, #8f50ff, #d13adf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-info {
  background: linear-gradient(180deg, #00c8ff, #0072ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-danger {
  background: linear-gradient(180deg, #ff5447, #f1076f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-success {
  background: linear-gradient(180deg, #08a50e, #cddc35);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-warning {
  background: linear-gradient(180deg, #f7971e, #ffd200);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-secondary {
  background: linear-gradient(180deg, #fc00ff, #00dbde);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-google {
  color: #dd4b39 !important;
}

.text-facebook {
  color: #3b5998 !important;
}

.text-twitter {
  color: #55acee !important;
}

.text-linkedin {
  color: #0976b4 !important;
}

.text-behance {
  color: #1769ff !important;
}

.text-dribbble {
  color: #ea4c89 !important;
}

.text-github {
  color: #333333 !important;
}

.text-youtube {
  color: #e52d27 !important;
}

/* Shadows */
.shadow-primary {
  box-shadow: 3px 5px 20px #7934f3 !important;
}

.shadow-secondary {
  box-shadow: 3px 5px 20px #94614f !important;
}

.shadow-success {
  box-shadow: 3px 5px 20px #04b962 !important;
}

.shadow-danger {
  box-shadow: 3px 5px 20px #f43643 !important;
}

.shadow-warning {
  box-shadow: 3px 5px 20px #ff8800 !important;
}

.shadow-info {
  box-shadow: 3px 5px 20px #14b6ff !important;
}

.shadow-light {
  box-shadow: 3px 5px 20px #bcbdbd !important;
}

.shadow-dark {
  box-shadow: 3px 5px 20px #000000 !important;
}

.shadow-facebook {
  box-shadow: 0 7px 30px rgba(59, 89, 152, 0.82);
}

.shadow-twitter {
  box-shadow: 0 7px 30px rgba(85, 172, 238, 0.82);
}

.shadow-gogle-plus {
  box-shadow: 0 7px 30px rgba(221, 75, 57, 0.82);
}

.shadow-linkedin {
  box-shadow: 0 7px 30px rgba(9, 118, 180, 0.82);
}

.shadow-pinterest {
  box-shadow: 0 7px 30px rgba(204, 33, 39, 0.82);
}

.shadow-youtube {
  box-shadow: 0 7px 30px rgba(229, 45, 39, 0.82);
}

.shadow-tumblr {
  box-shadow: 0 7px 30px rgba(53, 70, 92, 0.82);
}

.shadow-github {
  box-shadow: 0 7px 30px rgba(51, 51, 51, 0.82);
}

.shadow-behance {
  box-shadow: 0 7px 30px rgba(23, 105, 255, 0.82);
}

.shadow-dribbble {
  box-shadow: 0 7px 30px rgba(234, 76, 137, 0.82);
}

.shadow-skype {
  box-shadow: 0 7px 30px rgba(0, 175, 240, 0.82);
}

/* CheckBoxes & Radios */
[class*=icheck-material] {
  min-height: 22px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding-left: 0px;
}
[class*=icheck-material] > label {
  padding-left: 29px !important;
  min-height: 22px;
  line-height: 22px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
[class*=icheck-material] > input:first-child {
  position: absolute !important;
  opacity: 0;
  margin: 0;
  background-color: #787878;
  border-radius: 50%;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  display: block;
  width: 22px;
  height: 22px;
  outline: none;
  transform: scale(2);
  -ms-transform: scale(2);
  transition: opacity 0.3s, transform 0.3s;
}
[class*=icheck-material] > input:first-child:disabled {
  cursor: default;
}
[class*=icheck-material] > input:first-child:disabled + label,
[class*=icheck-material] > input:first-child:disabled + input[type=hidden] + label,
[class*=icheck-material] > input:first-child:disabled + label::before,
[class*=icheck-material] > input:first-child:disabled + input[type=hidden] + label::before {
  pointer-events: none;
  cursor: default;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
}
[class*=icheck-material] > input:first-child + label::before,
[class*=icheck-material] > input:first-child + input[type=hidden] + label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0.46);
  border-radius: 0.25rem;
  margin-left: -29px;
  box-sizing: border-box;
}
[class*=icheck-material] > input:first-child:checked + label::after,
[class*=icheck-material] > input:first-child:checked + input[type=hidden] + label::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 7px;
  height: 10px;
  border: solid 2px #fff;
  border-left: none;
  border-top: none;
  transform: translate(7.75px, 4.5px) rotate(45deg);
  -ms-transform: translate(7.75px, 4.5px) rotate(45deg);
  box-sizing: border-box;
}
[class*=icheck-material] > input:first-child:not(:checked):not(:disabled):hover + label::before,
[class*=icheck-material] > input:first-child:not(:checked):not(:disabled):hover + input[type=hidden] + label::before {
  border-width: 2px;
}
[class*=icheck-material] > input:first-child::-ms-check {
  opacity: 0;
  border-radius: 50%;
}
[class*=icheck-material] > input:first-child:active {
  transform: scale(0);
  -ms-transform: scale(0);
  opacity: 1;
  transition: opacity 0s, transform 0s;
}
[class*=icheck-material] > input[type=radio]:first-child + label::before,
[class*=icheck-material] > input[type=radio]:first-child + input[type=hidden] + label::before {
  border-radius: 50%;
}
[class*=icheck-material] > input[type=radio]:first-child:checked + label::before,
[class*=icheck-material] > input[type=radio]:first-child:checked + input[type=hidden] + label::before {
  background-color: transparent;
}
[class*=icheck-material] > input[type=radio]:first-child:checked + label::after,
[class*=icheck-material] > input[type=radio]:first-child:checked + input[type=hidden] + label::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: none;
  top: 5px;
  left: 5px;
  transform: none;
  -ms-transform: none;
}
[class*=icheck-material] > input[type=checkbox]:first-child:checked + label::after,
[class*=icheck-material] > input[type=checkbox]:first-child:checked + input[type=hidden] + label::after {
  width: 6px;
  height: 12px;
  transform: translate(7px, 2px) rotate(45deg);
  -ms-transform: translate(7px, 2px) rotate(45deg);
}

.icheck-inline {
  display: inline-block;
}
.icheck-inline + .icheck-inline {
  margin-left: 0.75rem;
  margin-top: 6px;
}

.icheck-material-primary > input:first-child {
  background-color: #7934f3;
}
.icheck-material-primary > input:first-child::-ms-check {
  background-color: #7934f3;
}
.icheck-material-primary > input:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-material-primary > input:first-child:not(:checked):not(:disabled):hover + input[type=hidden] + label::before {
  border-color: #7934f3;
}
.icheck-material-primary > input:first-child:checked + label::before,
.icheck-material-primary > input:first-child:checked + input[type=hidden] + label::before {
  background-color: #7934f3;
  border-color: #7934f3;
}
.icheck-material-primary > input:first-child:checked + label::after,
.icheck-material-primary > input:first-child:checked + input[type=hidden] + label::after {
  border-bottom-color: #fff;
  border-right-color: #fff;
}
.icheck-material-primary > input[type=radio]:first-child:checked + label::after,
.icheck-material-primary > input[type=radio]:first-child:checked + input[type=hidden] + label::after {
  background-color: #7934f3;
}

.icheck-material-success > input:first-child {
  background-color: #04b962;
}
.icheck-material-success > input:first-child::-ms-check {
  background-color: #04b962;
}
.icheck-material-success > input:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-material-success > input:first-child:not(:checked):not(:disabled):hover + input[type=hidden] + label::before {
  border-color: #04b962;
}
.icheck-material-success > input:first-child:checked + label::before,
.icheck-material-success > input:first-child:checked + input[type=hidden] + label::before {
  background-color: #04b962;
  border-color: #04b962;
}
.icheck-material-success > input:first-child:checked + label::after,
.icheck-material-success > input:first-child:checked + input[type=hidden] + label::after {
  border-bottom-color: #fff;
  border-right-color: #fff;
}
.icheck-material-success > input[type=radio]:first-child:checked + label::after,
.icheck-material-success > input[type=radio]:first-child:checked + input[type=hidden] + label::after {
  background-color: #04b962;
}

.icheck-material-danger > input:first-child {
  background-color: #f43643;
}
.icheck-material-danger > input:first-child::-ms-check {
  background-color: #f43643;
}
.icheck-material-danger > input:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-material-danger > input:first-child:not(:checked):not(:disabled):hover + input[type=hidden] + label::before {
  border-color: #f43643;
}
.icheck-material-danger > input:first-child:checked + label::before,
.icheck-material-danger > input:first-child:checked + input[type=hidden] + label::before {
  background-color: #f43643;
  border-color: #f43643;
}
.icheck-material-danger > input:first-child:checked + label::after,
.icheck-material-danger > input:first-child:checked + input[type=hidden] + label::after {
  border-bottom-color: #fff;
  border-right-color: #fff;
}
.icheck-material-danger > input[type=radio]:first-child:checked + label::after,
.icheck-material-danger > input[type=radio]:first-child:checked + input[type=hidden] + label::after {
  background-color: #f43643;
}

.icheck-material-info > input:first-child {
  background-color: #14b6ff;
}
.icheck-material-info > input:first-child::-ms-check {
  background-color: #14b6ff;
}
.icheck-material-info > input:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-material-info > input:first-child:not(:checked):not(:disabled):hover + input[type=hidden] + label::before {
  border-color: #14b6ff;
}
.icheck-material-info > input:first-child:checked + label::before,
.icheck-material-info > input:first-child:checked + input[type=hidden] + label::before {
  background-color: #14b6ff;
  border-color: #14b6ff;
}
.icheck-material-info > input:first-child:checked + label::after,
.icheck-material-info > input:first-child:checked + input[type=hidden] + label::after {
  border-bottom-color: #fff;
  border-right-color: #fff;
}
.icheck-material-info > input[type=radio]:first-child:checked + label::after,
.icheck-material-info > input[type=radio]:first-child:checked + input[type=hidden] + label::after {
  background-color: #14b6ff;
}

.icheck-material-warning > input:first-child {
  background-color: #ff8800;
}
.icheck-material-warning > input:first-child::-ms-check {
  background-color: #ff8800;
}
.icheck-material-warning > input:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-material-warning > input:first-child:not(:checked):not(:disabled):hover + input[type=hidden] + label::before {
  border-color: #ff8800;
}
.icheck-material-warning > input:first-child:checked + label::before,
.icheck-material-warning > input:first-child:checked + input[type=hidden] + label::before {
  background-color: #ff8800;
  border-color: #ff8800;
}
.icheck-material-warning > input:first-child:checked + label::after,
.icheck-material-warning > input:first-child:checked + input[type=hidden] + label::after {
  border-bottom-color: #fff;
  border-right-color: #fff;
}
.icheck-material-warning > input[type=radio]:first-child:checked + label::after,
.icheck-material-warning > input[type=radio]:first-child:checked + input[type=hidden] + label::after {
  background-color: #ff8800;
}

.icheck-material-secondary > input:first-child {
  background-color: #94614f;
}
.icheck-material-secondary > input:first-child::-ms-check {
  background-color: #94614f;
}
.icheck-material-secondary > input:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-material-secondary > input:first-child:not(:checked):not(:disabled):hover + input[type=hidden] + label::before {
  border-color: #94614f;
}
.icheck-material-secondary > input:first-child:checked + label::before,
.icheck-material-secondary > input:first-child:checked + input[type=hidden] + label::before {
  background-color: #94614f;
  border-color: #94614f;
}
.icheck-material-secondary > input:first-child:checked + label::after,
.icheck-material-secondary > input:first-child:checked + input[type=hidden] + label::after {
  border-bottom-color: #fff;
  border-right-color: #fff;
}
.icheck-material-secondary > input[type=radio]:first-child:checked + label::after,
.icheck-material-secondary > input[type=radio]:first-child:checked + input[type=hidden] + label::after {
  background-color: #94614f;
}

.icheck-material-dark > input:first-child {
  background-color: #000;
}
.icheck-material-dark > input:first-child::-ms-check {
  background-color: #000;
}
.icheck-material-dark > input:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-material-dark > input:first-child:not(:checked):not(:disabled):hover + input[type=hidden] + label::before {
  border-color: #000;
}
.icheck-material-dark > input:first-child:checked + label::before,
.icheck-material-dark > input:first-child:checked + input[type=hidden] + label::before {
  background-color: #000;
  border-color: #000;
}
.icheck-material-dark > input:first-child:checked + label::after,
.icheck-material-dark > input:first-child:checked + input[type=hidden] + label::after {
  border-bottom-color: #fff;
  border-right-color: #fff;
}
.icheck-material-dark > input[type=radio]:first-child:checked + label::after,
.icheck-material-dark > input[type=radio]:first-child:checked + input[type=hidden] + label::after {
  background-color: #000;
}

.icheck-material-white > input:first-child {
  background-color: #fff;
}
.icheck-material-white > input:first-child::-ms-check {
  background-color: #fff;
}
.icheck-material-white > input:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-material-white > input:first-child:not(:checked):not(:disabled):hover + input[type=hidden] + label::before {
  border-color: #fff;
}
.icheck-material-white > input:first-child:checked + label::before,
.icheck-material-white > input:first-child:checked + input[type=hidden] + label::before {
  background-color: #fff;
  border-color: #fff;
}
.icheck-material-white > input:first-child:checked + label::after,
.icheck-material-white > input:first-child:checked + input[type=hidden] + label::after {
  border-bottom-color: #000;
  border-right-color: #000;
}
.icheck-material-white > input[type=radio]:first-child:checked + label::after,
.icheck-material-white > input[type=radio]:first-child:checked + input[type=hidden] + label::after {
  background-color: #fff;
}

.input-group-addon [type=checkbox]:checked, .input-group-addon [type=checkbox]:not(:checked) {
  position: initial;
  opacity: 1;
  margin-top: 4px;
}
.input-group-addon [type=radio]:checked, .input-group-addon [type=radio]:not(:checked) {
  position: initial;
  opacity: 1;
  margin-top: 4px;
}

.navbar-sidenav-tooltip.show {
  display: none;
}

.card-body-icon {
  position: absolute;
  z-index: 0;
  top: -25px;
  right: -25px;
  font-size: 5rem;
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}

.pricing-table .list-group {
  box-shadow: none;
  padding-left: 1rem;
  padding-right: 1rem;
}
.pricing-table .card .card-body .list-group .list-group-item {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.1);
}
.pricing-table .card .card-body .list-group .list-group-item:first-child {
  border-top: 0px;
}
.pricing-table .card .card-body .list-group .list-group-item:last-child {
  border-bottom: 0px;
}
.pricing-table.pricing-table-color .card .card-body .list-group .list-group-item {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}

.price-title {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.price {
  font-size: 3.5em;
  line-height: 1.8em;
  font-weight: 600;
  margin-bottom: 0;
}

.currency {
  font-size: 35px;
}

.error-pages {
  margin-top: 230px;
}

.error-title {
  font-size: 140px;
  line-height: 140px;
  text-transform: uppercase;
  font-weight: bold;
}

.error-sub-title {
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
}

.bg-error {
  background-color: rgb(255, 255, 255);
  background-image: url(./../../../images/error-texture.png);
}

.bg-coming-soon {
  background-color: rgb(255, 255, 255);
  background-image: url(./../../../images/coming-soon.png);
}

.coming-soon {
  margin-top: 230px;
}

.coming-soon-title {
  font-size: 35px;
  line-height: 35px;
  text-transform: uppercase;
}

.card-authentication1 {
  width: 24rem;
}

.card-authentication2 {
  width: 52rem;
}

.bg-signup2 {
  background-color: rgb(0, 140, 255);
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.63), rgba(0, 0, 0, 0.68)), url(https://images.pexels.com/photos/1227520/pexels-photo-1227520.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500);
  height: 100%;
  border-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.bg-signin2 {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.63), rgba(0, 0, 0, 0.68)), url(https://images.pexels.com/photos/1227520/pexels-photo-1227520.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500);
  height: 100%;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.bg-reset-password2 {
  background-color: rgb(0, 140, 255);
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.63), rgba(0, 0, 0, 0.68)), url(https://images.pexels.com/photos/1227520/pexels-photo-1227520.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500);
  height: 100%;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.cd-horizontal-timeline {
  opacity: 0;
  margin: 2em auto;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.cd-horizontal-timeline::before {
  /* never visible - this is used in jQuery to check the current MQ */
  content: "mobile";
  display: none;
}
.cd-horizontal-timeline.loaded {
  /* show the timeline after events position has been set (using JavaScript) */
  opacity: 1;
}
.cd-horizontal-timeline .timeline {
  position: relative;
  height: 100px;
  width: 90%;
  margin: 0 auto;
}
.cd-horizontal-timeline .timeline ul li,
.cd-horizontal-timeline .timeline ol li {
  list-style: none;
}
.cd-horizontal-timeline .events-wrapper {
  position: relative;
  height: 100%;
  margin: 0 40px;
  overflow: hidden;
}
.cd-horizontal-timeline .events-wrapper::after, .cd-horizontal-timeline .events-wrapper::before {
  /* these are used to create a shadow effect at the sides of the timeline */
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 20px;
}
.cd-horizontal-timeline .events-wrapper::before {
  left: 0;
}
.cd-horizontal-timeline .events-wrapper::after {
  right: 0;
}
.cd-horizontal-timeline .events {
  /* this is the grey line/timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 49px;
  height: 2px;
  /* width will be set using JavaScript */
  background: #dfdfdf;
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  transition: transform 0.4s;
}
.cd-horizontal-timeline .filling-line {
  /* this is used to create the green line filling the timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #0d6efd;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}
.cd-horizontal-timeline .events a {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  padding-bottom: 15px;
  color: #383838;
  /* fix bug on Safari - text flickering while timeline translates */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.cd-horizontal-timeline .events a::after {
  /* this is used to create the event spot */
  content: "";
  position: absolute;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #dfdede;
  background-color: white;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  -moz-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}
.no-touch .cd-horizontal-timeline .events a:hover::after {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.cd-horizontal-timeline .events a.selected {
  pointer-events: none;
}
.cd-horizontal-timeline .events a.selected::after {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.cd-horizontal-timeline .events a.older-event::after {
  border-color: #0d6efd;
}
@media only screen and (min-width: 1100px) {
  .cd-horizontal-timeline {
    margin: 6em auto;
  }
  .cd-horizontal-timeline::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: "desktop";
  }
}

.cd-timeline-navigation a {
  /* these are the left/right arrows to navigate the timeline */
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  /* replace text with an icon */
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.cd-timeline-navigation a::after {
  /* arrow icon */
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background: url(./../../../images/svg/cd-arrow.svg) no-repeat 0 0;
}
.cd-timeline-navigation a.prev {
  left: 0;
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.cd-timeline-navigation a.next {
  right: 0;
}
.no-touch .cd-timeline-navigation a:hover {
  border-color: #0d6efd;
}
.cd-timeline-navigation a.inactive {
  cursor: not-allowed;
}
.cd-timeline-navigation a.inactive::after {
  background-position: 0 -16px;
}
.no-touch .cd-timeline-navigation a.inactive:hover {
  border-color: #dfdfdf;
}

.cd-horizontal-timeline .events-content {
  position: relative;
  width: 100%;
  margin: 2em 0;
  overflow: hidden;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  transition: height 0.4s;
}
.cd-horizontal-timeline .events-content li {
  list-style: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  padding: 0 5%;
  opacity: 0;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.cd-horizontal-timeline .events-content li.selected {
  /* visible event content */
  position: relative;
  z-index: 2;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.cd-horizontal-timeline .events-content li.enter-right, .cd-horizontal-timeline .events-content li.leave-right {
  -webkit-animation-name: cd-enter-right;
  -moz-animation-name: cd-enter-right;
  animation-name: cd-enter-right;
}
.cd-horizontal-timeline .events-content li.enter-left, .cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-name: cd-enter-left;
  -moz-animation-name: cd-enter-left;
  animation-name: cd-enter-left;
}
.cd-horizontal-timeline .events-content li.leave-right, .cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
  animation-direction: reverse;
}
.cd-horizontal-timeline .events-content li > * {
  margin: 0 auto;
}
.cd-horizontal-timeline .events-content em {
  display: block;
  font-style: italic;
  margin: 10px auto;
}
.cd-horizontal-timeline .events-content em::before {
  content: "- ";
}
.cd-horizontal-timeline .events-content em,
.cd-horizontal-timeline .events-content p {
  line-height: 1.6;
}

@-webkit-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-webkit-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
.cd-timeline {
  overflow: hidden;
  margin: 2em auto;
}

.cd-timeline__container {
  position: relative;
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 2em 0;
}
.cd-timeline__container::before {
  /* this is the vertical line */
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #d7e4ed;
}

@media only screen and (min-width: 1170px) {
  .cd-timeline {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .cd-timeline__container::before {
    left: 50%;
    margin-left: -2px;
  }
}
.cd-timeline__block {
  position: relative;
  margin: 2em 0;
}
.cd-timeline__block:after {
  /* clearfix */
  content: "";
  display: table;
  clear: both;
}
.cd-timeline__block:first-child {
  margin-top: 0;
}
.cd-timeline__block:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
  .cd-timeline__block {
    margin: 4em 0;
  }
}
.cd-timeline__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.cd-timeline__img img {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}
.cd-timeline__img.cd-timeline__img--picture {
  background: #75ce66;
}
.cd-timeline__img.cd-timeline__img--movie {
  background: #c03b44;
}
.cd-timeline__img.cd-timeline__img--location {
  background: #f0ca45;
}

@media only screen and (min-width: 1170px) {
  .cd-timeline__img {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
    /* Force Hardware Acceleration */
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .cd-timeline__img.cd-timeline__img--bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }
}
@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.cd-timeline__content {
  position: relative;
  margin-left: 60px;
  background: #fff;
  border-radius: 0.25em;
  padding: 1em;
  -webkit-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.22);
  box-shadow: 0 3px 0 rgba(0, 0, 0, 0.22);
}
.cd-timeline__content:after {
  /* clearfix */
  content: "";
  display: table;
  clear: both;
}
.cd-timeline__content::before {
  /* triangle next to content block */
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}
.cd-timeline__content h2 {
  color: #303e49;
}
.cd-timeline__content p {
  font-size: 1rem;
}

.cd-timeline__read-more,
.cd-timeline__date {
  font-size: 1rem;
}

.cd-timeline__content p {
  margin: 1em 0;
  line-height: 1.6;
}

.cd-timeline__read-more,
.cd-timeline__date {
  display: inline-block;
}

.cd-timeline__read-more {
  float: right;
  padding: 0.5em 1em;
  background: #7934f3;
  color: #fff;
  border-radius: 0.25em;
}
.cd-timeline__read-more:hover {
  background-color: #7934f3;
  color: #fff;
}

.cd-timeline__date {
  float: left;
  padding: 0.8em 0;
  opacity: 0.7;
}

@media only screen and (min-width: 768px) {
  .cd-timeline__content h2 {
    font-size: 2rem;
  }
  .cd-timeline__content p {
    font-size: 0.9rem;
  }
  .cd-timeline__read-more,
.cd-timeline__date {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-timeline__content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
    /* Force Hardware Acceleration */
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .cd-timeline__content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }
  .cd-timeline__read-more {
    float: left;
  }
  .cd-timeline__date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 1rem;
  }
  .cd-timeline__block:nth-child(even) .cd-timeline__content {
    float: right;
  }
  .cd-timeline__block:nth-child(even) .cd-timeline__content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }
  .cd-timeline__block:nth-child(even) .cd-timeline__read-more {
    float: right;
  }
  .cd-timeline__block:nth-child(even) .cd-timeline__date {
    left: auto;
    right: 122%;
    text-align: right;
  }
  .cd-timeline__content.cd-timeline__content--bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }
}
@media only screen and (min-width: 1170px) {
  /* inverse bounce effect on even content blocks */
  .cd-timeline__block:nth-child(even) .cd-timeline__content.cd-timeline__content--bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
/*Widgets*/
.w-circle-icon {
  width: 52px;
  height: 52px;
  line-height: 52px;
  font-size: 20px;
  text-align: center;
}

.media .w-icon i {
  font-size: 35px;
}

.icon-box {
  width: 52px;
  height: 52px;
  line-height: 55px;
  font-size: 25px;
  color: #383838;
  margin: auto;
  border: 0px solid #fff;
  border-radius: 50%;
  text-align: center;
}

.bootstrap-tagsinput .badge {
  line-height: 1.5;
  position: relative;
  margin: 0.125rem;
  padding: 0.625rem 0.625rem 0.5rem;
  transition: all 0.15s ease;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0.25rem;
  background-color: #7934f3;
  box-shadow: 0 1px 2px rgba(68, 68, 68, 0.25);
}

.badge-dot i {
  display: inline-block;
  width: 0.385rem;
  height: 0.385rem;
  margin-right: 0.385rem;
  vertical-align: middle;
  border-radius: 50%;
}

.chart {
  position: relative;
  display: inline-block;
  width: 110px !important;
  height: 110px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 110px !important;
  height: 110px !important;
}
.chart.chart-widget-pie {
  margin-top: 5px;
  margin-bottom: 5px;
}

.percent {
  display: inline-block;
  line-height: 110px !important;
  z-index: 2;
}
.percent:after {
  content: "%";
  margin-left: 0.1em;
  font-size: 0.8em;
}

.w_chart {
  position: relative;
  display: inline-block;
  width: 65px !important;
  height: 65px !important;
  text-align: center;
}
.w_chart canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 65px !important;
  height: 65px !important;
}

.w_percent {
  display: inline-block;
  line-height: 65px !important;
  z-index: 2;
  color: #353434;
}
.w_percent:after {
  content: "%";
  margin-left: 0.1em;
  font-size: 0.8em;
}

.fleet-status {
  position: relative;
  display: inline-block;
  width: 90px !important;
  height: 90px !important;
  text-align: center;
}
.fleet-status canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 90px !important;
  height: 90px !important;
}

.fleet-status-percent {
  display: inline-block;
  line-height: 90px !important;
  z-index: 2;
  color: #ffffff;
}
.fleet-status-percent:after {
  content: "%";
  margin-left: 0.1em;
  font-size: 0.8em;
  color: #ffffff;
}

.gmaps,
.gmaps-panaroma {
  height: 400px;
  background: #eeeeee;
  border-radius: 3px;
}

.error {
  color: #ff5656;
}

.footer {
  bottom: 0px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  padding: 12px 30px;
  position: absolute;
  right: 0;
  left: 250px;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#wrapper.toggled .footer {
  position: absolute;
  left: 0px;
}

.back-to-top {
  display: none;
  width: 40px;
  height: 40px;
  text-align: center;
  color: white;
  position: fixed;
  border-radius: 10%;
  bottom: 20px;
  right: 12px;
  background-color: #ff5722;
  z-index: 10000;
}
.back-to-top .fa-angle-double-up {
  margin-top: 20%;
  font-size: 20px;
}
.back-to-top:hover {
  color: white;
  background-color: #000;
  transition: all 0.5s;
}

/* Responsive */
@media only screen and (max-width: 1280px) {
  .search-bar {
    padding: 0rem 1rem 0 1rem;
  }
}
@media only screen and (max-width: 1199px) {
  .row.row-group > div {
    border-right: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
  .row.row-group > div:last-child {
    border-right: none;
    border-bottom: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .search-bar {
    padding: 0rem 15px;
  }
  .sidebar .brand-logo {
    position: absolute;
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .sidebar .close-btn {
    display: block;
  }
  .nav-tabs .nav-link {
    padding: 10px 10px;
  }
  #sidebar-wrapper {
    top: 0px;
    left: -250px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    box-shadow: none;
  }
  #sidebar-wrapper .sidebar-content {
    margin-top: 60px;
  }
  .toggle-menu {
    margin-left: 0px;
  }
  .card {
    margin-bottom: 25px;
  }
  .card-deck {
    margin-bottom: 0px;
  }
  .card-deck .card {
    margin-bottom: 25px;
  }
  .card-group {
    margin-bottom: 25px;
  }
  .content-wrapper {
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .topbar-nav .navbar {
    padding: 0px 15px;
  }
  .footer {
    position: absolute;
    left: 0px;
  }
  #wrapper.nav-collapsed #sidebar-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  #wrapper.nav-collapsed .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: #000;
    opacity: 0.6;
    z-index: 999;
    display: block;
  }
  #wrapper.nav-collapsed .toggle-menu,
#wrapper.nav-collapsed .content-wrapper {
    margin-left: 0px;
  }
  #wrapper.nav-collapsed .footer {
    position: absolute;
    left: 0px;
  }
  .hidden-xs {
    display: none !important;
  }
  .height-100v {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .search-bar {
    display: none;
  }
  .search-bar input {
    height: 60px;
    border: 1px solid rgba(255, 255, 255, 0);
    background-color: rgba(255, 255, 255, 0);
    border-radius: 0px;
  }
  .search-bar input:focus {
    border: 1px solid rgba(255, 255, 255, 0);
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
  }
  .search-bar .btn-search {
    background: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 0px;
    padding: 0 15px !important;
  }
  .search-bar .btn-search-back {
    background: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0);
    padding: 0 15px;
  }
  .search-arrow-back {
    display: flex;
    margin-right: 0px;
  }
  .full-search-bar {
    display: flex;
    align-items: center;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 100;
    background: #fff;
    height: 60px;
    padding: 0rem 0rem;
  }
  .search-btn-mobile {
    display: flex;
  }
  .topbar-nav .navbar .dropdown {
    position: static !important;
  }
  .topbar-nav .navbar .dropdown-menu {
    width: 100% !important;
  }
}
@media only screen and (max-width: 575px) {
  .bg-signup2 {
    height: 35rem;
    border-radius: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .bg-signin2 {
    height: 25rem;
    border-radius: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .bg-reset-password2 {
    height: 20rem;
    border-radius: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .search-bar .btn-search {
    padding: 0 20px;
  }
}
.my-drop-zone {
  min-height: 1.5rem;
  margin-bottom: 2rem;
  padding: 1rem;
  border: dashed 2px;
  border-radius: 0.125rem;
}

.nv-file-over {
  border-color: #dc3545;
}

.another-file-over-class {
  border-color: #28a745;
}