/* Background Colors */

.bg-primary {
  background-color: #7934f3 !important;
}

.bg-success {
  background-color: #04b962 !important;
}

.bg-info {
  background-color: #14b6ff !important;
}

.bg-secondary {
  background-color: #94614f !important;
}

.bg-danger {
  background-color: #f43643 !important;
}

.bg-dark {
  background-color: #0a151f !important;
}

.bg-dark-light {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.bg-warning {
  background-color: #ff8800 !important;
}

.bg-light {
  background-color: rgba(255, 255, 255, 0.125) !important;
}

.bg-contrast {
  background: rgba(255, 255, 255, 0.3) !important;
}

.bg-body {
  background: rgb(247, 247, 255) !important;
}

.bg-primary-light1 {
  background-color: rgba(144, 79, 254, 0.22);
}

.bg-primary-light2 {
  background-color: rgba(144, 79, 254, 0.42);
}

.gradient-primary-light {
  background-color: #8f50ff;
  background-image: radial-gradient(
    circle 30px at center,
    #f8aeff,
    #8f50ff
  ) !important;
}

.bg-success-light1 {
  background-color: rgba(8, 165, 14, 0.22);
}

.bg-success-light2 {
  background-color: rgba(8, 165, 14, 0.42);
}

.gradient-success-light {
  background-color: #0aa60f;
  background-image: radial-gradient(
    circle 30px at center,
    rgb(202, 219, 52),
    rgb(10, 166, 15)
  ) !important;
}

.bg-info-light1 {
  background-color: rgba(0, 129, 255, 0.22);
}

.bg-info-light2 {
  background-color: rgba(0, 129, 255, 0.42);
}

.gradient-info-light {
  background-color: #0074ff;
  background-image: radial-gradient(
    circle 30px at center,
    rgb(113, 222, 253),
    rgb(0, 116, 255)
  ) !important;
}

.bg-danger-light1 {
  background-color: rgba(245, 13, 85, 0.22);
}

.bg-danger-light2 {
  background-color: rgba(245, 13, 85, 0.42);
}

.gradient-danger-light {
  background-color: #f50d55;
  background-image: radial-gradient(
    circle 30px at center,
    rgb(251, 208, 206),
    #f50d55
  ) !important;
}

.bg-warning-light1 {
  background-color: rgba(247, 151, 30, 0.22);
}

.bg-warning-light2 {
  background-color: rgba(247, 152, 30, 0.42);
}

.gradient-warning-light {
  background-color: #f7981e;
  background-image: radial-gradient(
    circle 30px at center,
    rgb(253, 239, 176),
    #f7981e
  ) !important;
}

.bg-secondary-light1 {
  background-color: rgba(247, 3, 254, 0.22);
}

.bg-secondary-light2 {
  background-color: rgba(247, 3, 254, 0.42);
}

.gradient-secondary-light {
  background-color: #f703fe;
  background-image: radial-gradient(
    circle 30px at center,
    rgb(254, 219, 255),
    #f703fe
  ) !important;
}

.bg-dark-light1 {
  background-color: rgba(0, 0, 0, 0.22);
}

.bg-dark-light2 {
  background-color: rgba(0, 0, 0, 0.42);
}

.gradient-dark-light {
  background-color: #000000;
  background-image: radial-gradient(
    circle 30px at center,
    rgb(173, 172, 172),
    #000000
  ) !important;
}

.bg-white-light1 {
  background-color: rgba(255, 255, 255, 0.22);
}

.bg-white-light2 {
  background-color: rgba(255, 255, 255, 0.42);
}

.gradient-white-light {
  background-color: #ffffff;
  background-image: radial-gradient(
    circle 30px at center,
    rgb(255, 255, 255),
    rgba(0, 0, 0, 0.78)
  ) !important;
}

.bg-facebook {
  background-color: #3b5998 !important;
}

.bg-twitter {
  background-color: #55acee !important;
}

.bg-google-plus {
  background-color: #dd4b39 !important;
}

.bg-linkedin {
  background-color: #0976b4 !important;
}

.bg-pinterest {
  background-color: #cc2127 !important;
}

.bg-youtube {
  background-color: #e52d27 !important;
}

.bg-tumblr {
  background-color: #35465c !important;
}

.bg-github {
  background-color: #333333 !important;
}

.bg-behance {
  background-color: #1769ff !important;
}

.bg-dribbble {
  background-color: #ea4c89 !important;
}

.bg-skype {
  background-color: #00aff0 !important;
}

.gradient-bloody {
  background: #f54ea2;
  background: -webkit-linear-gradient(45deg, #f54ea2, #ff7676) !important;
  background: linear-gradient(45deg, #f54ea2, #ff7676) !important;
}

.gradient-scooter {
  background: #4facfe;
  background: -webkit-linear-gradient(
    45deg,
    #4facfe 0%,
    #00f2fe 100%
  ) !important;
  background: linear-gradient(45deg, #4facfe 0%, #00f2fe 100%) !important;
}

.gradient-blooker {
  background: #fa709a;
  background: -webkit-linear-gradient(
    45deg,
    #fa709a 0%,
    #fee140 100%
  ) !important;
  background: linear-gradient(45deg, #fa709a 0%, #fee140 100%) !important;
}

.gradient-quepal {
  background: #11998e;
  background: -webkit-linear-gradient(45deg, #11998e, #38ef7d) !important;
  background: linear-gradient(45deg, #11998e, #38ef7d) !important;
}

.gradient-purpink {
  background: #7f00ff;
  background: -webkit-linear-gradient(45deg, #7f00ff, #e100ff) !important;
  background: linear-gradient(45deg, #7f00ff, #e100ff) !important;
}

.gradient-ibiza {
  background: #ee0979;
  background: -webkit-linear-gradient(45deg, #ee0979, #ff6a00) !important;
  background: linear-gradient(45deg, #ee0979, #ff6a00) !important;
}

.gradient-deepblue {
  background: #6a11cb;
  background: -webkit-linear-gradient(45deg, #6a11cb, #2575fc) !important;
  background: linear-gradient(45deg, #6a11cb, #2575fc) !important;
}

.gradient-branding {
  background: #6a11cb;
  background: -webkit-linear-gradient(180deg, #2af598, #009efd) !important;
  background: linear-gradient(180deg, #2af598, #009efd) !important;
}

.gradient-ohhappiness {
  background: #00b09b;
  background: -webkit-linear-gradient(45deg, #00b09b, #96c93d) !important;
  background: linear-gradient(45deg, #00b09b, #96c93d) !important;
}

.gradient-dusk {
  background: #2c3e50;
  background: -webkit-linear-gradient(45deg, #2c3e50, #fd746c) !important;
  background: linear-gradient(45deg, #2c3e50, #fd746c) !important;
}

.gradient-meridian {
  background: #283c86;
  background: -webkit-linear-gradient(45deg, #283c86, #45a247) !important;
  background: linear-gradient(45deg, #283c86, #45a247) !important;
}

.gradient-violet {
  background: #4776e6;
  background: -webkit-linear-gradient(45deg, #4776e6, #8e54e9) !important;
  background: linear-gradient(45deg, #4776e6, #8e54e9) !important;
}

.gradient-titanium {
  background: #283048;
  background: -webkit-linear-gradient(45deg, #283048, #859398) !important;
  background: linear-gradient(45deg, #283048, #859398) !important;
}

.gradient-shifter {
  background: #bc4e9c;
  background: -webkit-linear-gradient(45deg, #bc4e9c, #f80759) !important;
  background: linear-gradient(45deg, #bc4e9c, #f80759) !important;
}

.gradient-army {
  background: #414d0b;
  background: -webkit-linear-gradient(45deg, #414d0b, #727a17) !important;
  background: linear-gradient(45deg, #414d0b, #727a17) !important;
}

.gradient-orange {
  background: #fc4a1a;
  background: -webkit-linear-gradient(45deg, #fc4a1a, #f7b733) !important;
  background: linear-gradient(45deg, #fc4a1a, #f7b733) !important;
}

.gradient-yoda {
  background: #ff0099;
  background: -webkit-linear-gradient(45deg, #ff0099, #493240) !important;
  background: linear-gradient(45deg, #ff0099, #493240) !important;
}

.gradient-royal {
  background: #141e30;
  background: -webkit-linear-gradient(45deg, #141e30, #2c527d) !important;
  background: linear-gradient(45deg, #141e30, #2c527d) !important;
}

.gradient-knight {
  background: #ba8b02;
  background: -webkit-linear-gradient(45deg, #ba8b02, #181818) !important;
  background: linear-gradient(45deg, #ba8b02, #181818) !important;
}

.gradient-forest {
  background: #000428;
  background: -webkit-linear-gradient(45deg, #000428, #004e92) !important;
  background: linear-gradient(45deg, #000428, #004e92) !important;
}

.gradient-redmist {
  background: #353434;
  background: -webkit-linear-gradient(45deg, #353434, #e74c3c) !important;
  background: linear-gradient(45deg, #353434, #e74c3c) !important;
}

.gradient-steelgray {
  background: #1f1c2c;
  background: -webkit-linear-gradient(45deg, #1f1c2c, #928dab) !important;
  background: linear-gradient(45deg, #1f1c2c, #928dab) !important;
}

.gradient-influenza {
  background: #c04848;
  background: -webkit-linear-gradient(45deg, #c04848, #480048) !important;
  background: linear-gradient(45deg, #c04848, #480048) !important;
}

.gradient-sylvia {
  background: #ff4b1f;
  background: -webkit-linear-gradient(45deg, #ff4b1f, #ff9068) !important;
  background: linear-gradient(45deg, #ff4b1f, #ff9068) !important;
}

.gradient-jshine {
  background: #12c2e9;
  background: -webkit-linear-gradient(
    45deg,
    #12c2e9,
    #c471ed,
    #f64f59
  ) !important;
  background: linear-gradient(45deg, #12c2e9, #c471ed, #f64f59) !important;
}

.gradient-lovekiss {
  background: #ff0844;
  background: -webkit-linear-gradient(45deg, #ff0844, #ffb199) !important;
  background: linear-gradient(45deg, #ff0844 0%, #ffb199 100%) !important;
}

.gradient-hossein {
  background: #f7ff00;
  background: -webkit-linear-gradient(45deg, #f7ff00, #db36a4) !important;
  background: linear-gradient(45deg, #f7ff00, #db36a4) !important;
}

.gradient-primary {
  background: #8f50ff;
  background: -webkit-linear-gradient(180deg, #8f50ff, #d13adf) !important;
  background: linear-gradient(180deg, #8f50ff, #d13adf) !important;
}

.gradient-info {
  background: #00c8ff;
  background: -webkit-linear-gradient(180deg, #00c8ff, #0072ff) !important;
  background: linear-gradient(180deg, #00c8ff, #0072ff) !important;
}

.gradient-danger {
  background: #ff5447;
  background: -webkit-linear-gradient(180deg, #ff5447, #f1076f) !important;
  background: linear-gradient(180deg, #ff5447, #f1076f) !important;
}

.gradient-success {
  background: #08a50e;
  background: -webkit-linear-gradient(180deg, #08a50e, #cddc35) !important;
  background: linear-gradient(180deg, #08a50e, #cddc35) !important;
}

.gradient-warning {
  background: #f7971e;
  background: -webkit-linear-gradient(180deg, #f7971e, #ffd200) !important;
  background: linear-gradient(180deg, #f7971e, #ffd200) !important;
}

.gradient-secondary {
  background: #fc00ff;
  background: -webkit-linear-gradient(180deg, #fc00ff, #00dbde) !important;
  background: linear-gradient(180deg, #fc00ff, #00dbde) !important;
}

.gradient-esinto {
  background: #0a0707;
  background-image: -webkit-linear-gradient(45deg, #0a0707 50%, #ff9800 50%);
  background-image: linear-gradient(45deg, #0a0707 50%, #ff9800 50%);
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-dunada {
  background: #0a0707;
  background-image: -webkit-linear-gradient(45deg, #000000 50%, #0085ff 50%);
  background-image: linear-gradient(45deg, #000000 50%, #0085ff 50%);
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-linga {
  background: #0a0707;
  background-image: -webkit-linear-gradient(45deg, #000000 50%, #7020ff 50%);
  background-image: linear-gradient(45deg, #000000 50%, #7020ff 50%);
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-blkw {
  background: #0a0707;
  background-image: -webkit-linear-gradient(45deg, #000000 50%, #fff 50%);
  background-image: linear-gradient(45deg, #000000 50%, #fff 50%);
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-rainbow {
  background: #00bfc8;
  background-image: -webkit-radial-gradient(
    circle 248px at center,
    rgb(0, 242, 96),
    rgb(5, 117, 230)
  );
  background-image: radial-gradient(
    circle 248px at center,
    rgb(0, 242, 96),
    rgb(5, 117, 230)
  );
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-smile {
  background: #16d9e3;
  background-image: -webkit-radial-gradient(
    circle 248px at center,
    #16d9e3 0%,
    #30c7ec 47%,
    #46aef7 100%
  );
  background-image: radial-gradient(
    circle 248px at center,
    #609cf7 0%,
    #0741cc 47%,
    #3f51b5 100%
  );
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-veer {
  background: #799f0c;
  background-image: -webkit-radial-gradient(
    circle 248px at center,
    rgb(255, 224, 0),
    rgb(121, 159, 12)
  );
  background-image: radial-gradient(
    circle 248px at center,
    rgb(255, 224, 0),
    rgb(121, 159, 12)
  );
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-worldcup {
  background: #f14793;
  background-image: -webkit-radial-gradient(
    circle 248px at center,
    #f14793,
    #5204ce
  );
  background-image: radial-gradient(circle 248px at center, #f14793, #5204ce);
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-pinki {
  background: #f1ea07;
  background-image: -webkit-radial-gradient(
    circle 248px at center,
    #f1ea07,
    #f10707
  );
  background-image: radial-gradient(circle 248px at center, #f1ea07, #f10707);
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.gradient-multiple-colors {
  background-image: linear-gradient(
    567deg,
    rgba(165, 42, 4, 0.89),
    rgba(113, 102, 8, 0.89),
    rgba(13, 95, 16, 0.93),
    rgba(4, 79, 88, 0.94),
    rgba(19, 56, 86, 0.9),
    rgba(24, 32, 78, 0.94),
    rgba(100, 8, 115, 0.95)
  );
  background-size: 100% 100%;
  background-position: center;
  transition: background 0.3s;
  border: 0px solid rgba(255, 255, 255, 0.125);
}

.texture-info {
  background-image: url(./../../../images/textures/1.png);
  background-size: cover;
  background-position: center;
}

.texture-danger {
  background-image: url(./../../../images/textures/2.png);
  background-size: cover;
  background-position: center;
}

.texture-success {
  background-image: url(./../../../images/textures/3.png);
  background-size: cover;
  background-position: center;
}

.texture-warning {
  background-image: url(./../../../images/textures/4.png);
  background-size: cover;
  background-position: center;
}

.texture-primary {
  background-image: url(./../../../images/textures/5.png);
  background-size: cover;
  background-position: center;
}

.texture-wave-a {
  background-image: url(./../../../images/textures/texture-a.png);
  background-size: cover;
  background-position: center;
}

.texture-wave-b {
  background-image: url(./../../../images/textures/texture-b.png);
  background-size: cover;
  background-position: center;
}

.texture-wave-c {
  background-image: url(./../../../images/textures/texture-c.png);
  background-size: cover;
  background-position: center;
}

.texture-wave-d {
  background-image: url(./../../../images/textures/texture-d.png);
  background-size: cover;
  background-position: center;
}

.texture-wave-e {
  background-image: url(./../../../images/textures/texture-e.png);
  background-size: cover;
  background-position: center;
}

.texture-wave-f {
  background-image: url(./../../../images/textures/texture-f.png);
  background-size: cover;
  background-position: center;
}

/* Borders */

.border-left-xs {
  border-left: 1px solid !important;
}

.border-right-xs {
  border-right: 1px solid !important;
}

.border-top-xs {
  border-top: 1px solid !important;
}

.border-bottom-xs {
  border-bottom: 1px solid !important;
}

.border-left-sm {
  border-left: 2px solid !important;
}

.border-right-sm {
  border-right: 2px solid !important;
}

.border-top-sm {
  border-top: 2px solid !important;
}

.border-bottom-sm {
  border-bottom: 2px solid !important;
}

.border-left-md {
  border-left: 3px solid !important;
}

.border-right-md {
  border-right: 3px solid !important;
}

.border-top-md {
  border-top: 3px solid !important;
}

.border-bottom-md {
  border-bottom: 3px solid !important;
}

.border-left-lg {
  border-left: 4px solid !important;
}

.border-right-lg {
  border-right: 4px solid !important;
}

.border-top-lg {
  border-top: 4px solid !important;
}

.border-bottom-lg {
  border-bottom: 4px solid !important;
}

.border-sm {
  border: 3px solid;
}

.border-primary {
  border-color: #7934f3 !important;
}

.border-success {
  border-color: #04b962 !important;
}

.border-info {
  border-color: #14b6ff !important;
}

.border-secondary {
  border-color: #94614f !important;
}

.border-secondary-light {
  border-color: #33444a !important;
}

.border-danger {
  border-color: #f43643 !important;
}

.border-dark {
  border-color: #353434 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-white-2 {
  border-color: rgba(255, 255, 255, 0.33) !important;
}

.border-white-3 {
  border-color: rgba(255, 255, 255, 0.12) !important;
}

.border-warning {
  border-color: #ff8800 !important;
}

.border-light {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.border-light-2 {
  border-color: rgba(0, 0, 0, 0.08) !important;
}

.border-light-3 {
  border-color: rgba(0, 0, 0, 0.05) !important;
}

/* Text Colors */

.text-primary {
  color: #7934f3 !important;
}

.text-success {
  color: #04b962 !important;
}

.text-info {
  color: #14b6ff !important;
}

.text-secondary {
  color: #94614f !important;
}

.text-danger {
  color: #f43643 !important;
}

.text-dark {
  color: #0a151f !important;
}

.text-warning {
  color: #ff8800 !important;
}

.text-light {
  color: rgba(255, 255, 255, 0.125) !important;
}

.text-light-1 {
  color: rgba(255, 255, 255, 0.7) !important;
}

.text-light-2 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-light-3 {
  color: rgba(255, 255, 255, 0.2) !important;
}

.text-gradient-primary {
  background: linear-gradient(180deg, #8f50ff, #d13adf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-info {
  background: linear-gradient(180deg, #00c8ff, #0072ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-danger {
  background: linear-gradient(180deg, #ff5447, #f1076f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-success {
  background: linear-gradient(180deg, #08a50e, #cddc35);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-warning {
  background: linear-gradient(180deg, #f7971e, #ffd200);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-secondary {
  background: linear-gradient(180deg, #fc00ff, #00dbde);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-google {
  color: #dd4b39 !important;
}

.text-facebook {
  color: #3b5998 !important;
}

.text-twitter {
  color: #55acee !important;
}

.text-linkedin {
  color: #0976b4 !important;
}

.text-behance {
  color: #1769ff !important;
}

.text-dribbble {
  color: #ea4c89 !important;
}

.text-github {
  color: #333333 !important;
}

.text-youtube {
  color: #e52d27 !important;
}

/* Shadows */

.shadow-primary {
  box-shadow: 3px 5px 20px #7934f3 !important;
}

.shadow-secondary {
  box-shadow: 3px 5px 20px #94614f !important;
}

.shadow-success {
  box-shadow: 3px 5px 20px #04b962 !important;
}

.shadow-danger {
  box-shadow: 3px 5px 20px #f43643 !important;
}

.shadow-warning {
  box-shadow: 3px 5px 20px #ff8800 !important;
}

.shadow-info {
  box-shadow: 3px 5px 20px #14b6ff !important;
}

.shadow-light {
  box-shadow: 3px 5px 20px #bcbdbd !important;
}

.shadow-dark {
  box-shadow: 3px 5px 20px #000000 !important;
}

.shadow-facebook {
  box-shadow: 0 7px 30px rgba(59, 89, 152, 0.82);
}

.shadow-twitter {
  box-shadow: 0 7px 30px rgba(85, 172, 238, 0.82);
}

.shadow-gogle-plus {
  box-shadow: 0 7px 30px rgba(221, 75, 57, 0.82);
}

.shadow-linkedin {
  box-shadow: 0 7px 30px rgba(9, 118, 180, 0.82);
}

.shadow-pinterest {
  box-shadow: 0 7px 30px rgba(204, 33, 39, 0.82);
}

.shadow-youtube {
  box-shadow: 0 7px 30px rgba(229, 45, 39, 0.82);
}

.shadow-tumblr {
  box-shadow: 0 7px 30px rgba(53, 70, 92, 0.82);
}

.shadow-github {
  box-shadow: 0 7px 30px rgba(51, 51, 51, 0.82);
}

.shadow-behance {
  box-shadow: 0 7px 30px rgba(23, 105, 255, 0.82);
}

.shadow-dribbble {
  box-shadow: 0 7px 30px rgba(234, 76, 137, 0.82);
}

.shadow-skype {
  box-shadow: 0 7px 30px rgba(0, 175, 240, 0.82);
}
