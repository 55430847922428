/* Topbar Header */

.topbar-nav .navbar {
  padding: 0px 25px;
  z-index: 999;
  height: 60px;
  background: #fff;
  -webkit-box-shadow: 0 5px 15px rgba(57, 98, 254, 0.02),
    0 3px 6px rgba(117, 108, 254, 0.12);
  box-shadow: 0 5px 15px rgba(57, 98, 254, 0.02),
    0 3px 6px rgba(117, 108, 254, 0.12);
}

.toggle-menu {
  margin-left: 250px;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 32px;
  border-radius: 0;
  background: rgb(0 0 0 / 0.08);
  text-align: center;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.right-nav-link a.nav-link {
  padding-right: 0.7rem !important;
  padding-left: 0.7rem !important;
  font-size: 20px;
  color: #353434;
}

/* SearachBar */

.search-bar {
  padding: 0rem 20rem 0 1rem;
  width: auto;
}

.search-arrow-back,
.search-btn-mobile {
  display: none;
}

.search-bar {
  .btn-search-back {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 20px;
    border-radius: 0px;

    i {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .btn-search {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(0, 0, 0, 0.25);
    padding: 0 30px !important;
    border-radius: 0px;

    /* height: calc(1.3em + .75rem + 2px); */

    i {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  input {
    height: calc(1.3em + 0.75rem + 2px);
    border: 1px solid rgba(0, 0, 0, 0.25);
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    border-radius: 0px;

    &:focus {
      border: 1px solid #7934f3;
      box-shadow: none;
    }
  }
}
