.h1,
h1 {
  font-size: 48px;
  line-height: 52px;
}
.h2,
h2 {
  font-size: 38px;
  line-height: 42px;
}
.h3,
h3 {
  font-size: 30px;
  line-height: 34px;
}
.h4,
h4 {
  font-size: 24px;
  line-height: 28px;
}
.h5,
h5 {
  font-size: 18px;
  line-height: 22px;
}
.h6,
h6 {
  font-size: 14px;
  line-height: 18px;
}
.display-1 {
  font-size: 6rem;
}
.display-2 {
  font-size: 5.5rem;
}
.display-3 {
  font-size: 4.5rem;
}
.display-4 {
  font-size: 3.5rem;
}
.line-height-0 {
  line-height: 0;
}
.line-height-5,
.line-height-10 {
  line-height: 5px;
}
.blockquote-footer {
  color: #615f5f;
}
.small,
small {
  font-size: 75%;
  font-weight: $font-weight-normal;
}
.small-font {
  font-size: 14px;
}
.extra-small-font {
  font-size: 12px;
}
