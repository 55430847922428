.error-pages {
	margin-top: 230px;
  }
  .error-title {
	font-size: 140px;
	line-height: 140px;
	text-transform: uppercase;
	font-weight: bold;
  }
  .error-sub-title {
	font-size: 30px;
	line-height: 30px;
	text-transform: uppercase;
  }
  .bg-error {
	background-color: rgb(255, 255, 255);
	background-image: url(./../../../images/error-texture.png);
  }
  .bg-coming-soon {
	background-color: rgb(255, 255, 255);
	background-image: url(./../../../images/coming-soon.png);
  }
  .coming-soon {
	margin-top: 230px;
  }
  .coming-soon-title {
	font-size: 35px;
	line-height: 35px;
	text-transform: uppercase;
  }
  .card-authentication1 {
	width: 24rem;
  }
  .card-authentication2 {
	width: 52rem;
  }
  .bg-signup2 {
	background-color: rgb(0, 140, 255);
	background: linear-gradient(45deg, rgba(0, 0, 0, 0.63), rgba(0, 0, 0, 0.68)),
	  url(https://images.pexels.com/photos/1227520/pexels-photo-1227520.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500);
	height: 100%;
	border-radius: 0;
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
  }
  .bg-signin2 {
	background: linear-gradient(45deg, rgba(0, 0, 0, 0.63), rgba(0, 0, 0, 0.68)),
	  url(https://images.pexels.com/photos/1227520/pexels-photo-1227520.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500);
	height: 100%;
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
  }
  .bg-reset-password2 {
	background-color: rgb(0, 140, 255);
	background: linear-gradient(45deg, rgba(0, 0, 0, 0.63), rgba(0, 0, 0, 0.68)),
	  url(https://images.pexels.com/photos/1227520/pexels-photo-1227520.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500);
	height: 100%;
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
  }
  