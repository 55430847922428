.footer {
  bottom: 0px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  padding: 12px 30px;
  position: absolute;
  right: 0;
  left: 250px;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#wrapper.toggled .footer {
  position: absolute;
  left: 0px;
}

.back-to-top {
  display: none;
  width: 40px;
  height: 40px;
  text-align: center;
  color: white;
  position: fixed;
  border-radius: 10%;
  bottom: 20px;
  right: 12px;
  background-color: #ff5722;
  z-index: 10000;

  .fa-angle-double-up {
    margin-top: 20%;
    font-size: 20px;
  }

  &:hover {
    color: white;
    background-color: #000;
    transition: all 0.5s;
  }
}
