.skill-img {
  height: 35px;
}

.page-title {
  font-size: $font-size-lg;
  line-height: 20px;
}

.breadcrumb {
  padding: 0;
  background-color: transparent;
}

.sidebar-menu li a i {
  &:first-child {
    margin-right: 10px;
    font-size: 18px;
  }
  &:last-child {
    margin-right: 10px;
    font-size: 12px;
  }
}

.lightbox-thumb {
  margin-bottom: 15px;
  width: 100%;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.mail-img {
  width: 50px;
}

.row.row-group > div {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.row.row-group > div:last-child {
  border-right: none;
}

code {
  font-size: 87.5%;
  color: #4683fd;
  word-break: break-word;
}

.breadcrumb-item.active {
  color: #615f5f;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: #615f5f;
}

.fonticon-container {
  > .fonticon-wrap {
    float: left;
    width: 40px;
    height: 40px;
    line-height: 1.8rem;
    text-align: center;
    border-radius: 0.1875rem;
    margin-right: $spacer;
    margin-bottom: $spacer * 1.5;
    > i {
      font-size: 1.5rem;
      &.fa {
        font-size: 1.5rem !important;
      }
    }
    &.youtube {
      width: 100px;
    }
  }
  &:hover {
    i {
      color: theme-color("danger");
    }
  }

  > .fonticon-classname,
  > .fonticon-unit {
    display: block;
    font-size: $font-size-base;
    line-height: 1;
  }
}

.user-lock {
  height: 150px !important;
}

.user-lock-img {
  width: 130px;
  margin: auto;
  img {
    width: 100%;
    border-radius: 50%;
    margin-top: 80px;
    border: 4px solid $white;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }
}

.payment-icons img {
  width: 100px;
}

.icon a {
  &:hover {
    background: #7934f3;
    color: $white;
    text-decoration: none;
  }
  display: block;
  color: #656464;
  padding: 8px;
  line-height: 32px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 2px;
  i {
    padding-right: 10px;
  }
}

.icon-section {
  clear: both;
  overflow: hidden;
}

.icon-container {
  width: 240px;
  padding: 0.7em 0;
  float: left;
  position: relative;
  text-align: left;
  [class^="ti-"],
  [class*=" ti-"] {
    color: #656464;
    position: absolute;
    margin-top: 3px;
    transition: 0.3s;
  }
  &:hover {
    [class^="ti-"],
    [class*=" ti-"] {
      font-size: 2.2em;
      margin-top: -5px;
    }
    .icon-name {
      color: #656464;
    }
  }
}

.icon-name {
  color: #656464;
  margin-left: 35px;
  transition: 0.3s;
}

.icon-container:hover .icon-name {
  margin-left: 45px;
}

.preview {
  padding: 15px 0;
  position: relative;
}

.icon-preview-box div:hover {
  background: rgba(255, 255, 255, 0.3);
}

.show-code {
  color: #656464;
}

.icons {
  font-size: 15px;
  padding-right: 7px;
}

.name {
  font-size: 15px;
}

.preview a {
  padding: 15px;
  &:hover {
    padding: 15px;
    text-decoration: none;
  }
  i {
    margin-right: 10px;
    font-size: 18px;
  }
}

.flag-icon-list {
  div {
    cursor: pointer;
    line-height: 60px;
    white-space: nowrap;
    color: #656464;
  }
  i {
    -webkit-transition: all 0.2s;
    -webkit-transition: font-size 0.2s;
    display: inline-block;
    font-size: 18px;
    margin: 0 15px 0 10px;
    text-align: left;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    -o-transition: font-size 0.2s;
    transition: font-size 0.2s;
    vertical-align: middle;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
}
.product-div {
  width: auto;
  height: auto;
  background: rgba(255, 255, 255, 0);
  padding: 5px;
  text-align: center;
  border: 1px solid rgba(66, 59, 116, 0.15);
  border-radius: 4px;

  img {
    width: 40px;
    height: 40px;
  }
}

.product-img {
  height: 32px;
}

.customer-img {
  height: 55px;
}

.w_chart {
  position: relative;
  display: inline-block;
  width: 65px !important;
  height: 65px !important;
  text-align: center;
}

.w_percent {
  display: inline-block;
  line-height: 65px !important;
  z-index: 2;
  color: #353434;
}

.fleet-status {
  position: relative;
  display: inline-block;
  width: 90px !important;
  height: 90px !important;
  text-align: center;
}

.fleet-status canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 90px !important;
  height: 90px !important;
}
.fleet-status-percent {
  display: inline-block;
  line-height: 90px !important;
  z-index: 2;
  color: #ffffff;
}
.fleet-status-percent:after {
  content: "%";
  margin-left: 0.1em;
  font-size: 0.8em;
  color: #ffffff;
}

.dash-array-chart-box {
  width: 105px;
  height: 90px;
  position: relative;
  top: -15px;
  right: 30px;
}

.font-33 {
  font-size: 33px;
}

.chart-container {
  position: relative;
  height: 330px;
}
.chart-container-1 {
  position: relative;
  height: 260px;
}

.chart-container-2 {
  position: relative;
  height: 188px;
}

.chart-container-3 {
  position: relative;
  height: 188px;
}

.chart-container-4 {
  position: relative;
  height: 162px;
}

.chart-container-5 {
  position: relative;
  height: 110px;
}

.chart-container-6 {
  position: relative;
  height: 205px;
}

.chart-container-7 {
  position: relative;
  height: 60px;
}
.chart-container-8 {
  position: relative;
  height: 260px;
}
.chart-container-9 {
  position: relative;
  height: 280px;
}
.chart-container-10 {
  position: relative;
  height: 300px;
  top: 20px;
}
.chart-container-11 {
  position: relative;
  height: 280px;
}

.chart-container-12 {
  position: relative;
  height: 160px;
}
.chart-container-13 {
  position: relative;
  height: 240px;
}
.chart-container-14 {
  position: relative;
  height: 40px;
}

.chart-container-15 {
  position: relative;
  height: 180px;
}

.height-240 {
  height: 240px;
  width: 100%;
  display: block;
}

.nav-pills,
.nav-tabs {
  margin: 0 0 25px;
}

.icon-box {
  width: 52px;
  height: 52px;
  line-height: 52px;
  font-size: 25px;
  color: #383838;
  margin: auto;
  border: 0px solid #fff;
  border-radius: 50%;
  text-align: center;
}

.icon-box i {
  color: #000;
  font-size: 25px;
  position: relative;
}

.demo-heading {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 0.25rem;
  color: #615f5f;
}
.badge-top {
  position: absolute;
  top: 15px;
}
.users {
  width: 40px;
  margin-right: -16px;
}
.height-100v {
  height: 100vh;
}
