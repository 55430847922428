.popover-header {
  background-color: #fff;
}

.popover {
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
  border: none;
}
.popover-body {
  color: #000;
}
