/* List Groups */

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  color: #383838;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.list-group-item-action {
  color: #7934f3;

  &:hover,
  &:focus {
    color: #7934f3;
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.list-group-item {
  &.disabled,
  &:disabled {
    color: #7934f3;
    background-color: rgba(255, 255, 255, 0.2);
  }
}
