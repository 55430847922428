.btn-social {
  width: 42px;
  height: 42px;
  font-size: 16px;
  line-height: 42px;
  display: inline-block;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.125);
  color: #fff;
  text-align: center;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    color: #fff;
  }
}

.btn-social-text {
  font-size: 0.72rem;
  text-transform: uppercase;
  padding: 10px 20px;
  font-weight: 600;
  border: 1px solid transparent;
  display: inline-block;
  background: #ddd;
  color: #fff;
  text-align: center;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;

  i {
    font-size: 0.95rem;
    padding-right: 4px;
    vertical-align: middle;
    display: table-cell;
  }

  span {
    display: table-cell;
    vertical-align: middle;
  }

  &:hover {
    outline: 0;
    color: #fff;
  }
}

.btn-social {
  &:focus,
  &:active {
    outline: 0;
    color: #fff;
  }
}

.btn-social-circle {
  border-radius: 50%;
}

.btn-outline-facebook {
  color: #3b5998;
  background-color: #fff;
  border-color: #3b5998;

  &:hover,
  &:focus {
    color: #fff;
    background-color: #3b5998;
  }
}

.btn-outline-twitter {
  color: #55acee;
  background-color: #fff;
  border-color: #55acee;

  &:hover,
  &:focus {
    background-color: #55acee;
    color: #fff;
  }
}

.btn-outline-google-plus {
  color: #dd4b39;
  background-color: #fff;
  border-color: #dd4b39;

  &:hover,
  &:focus {
    background-color: #dd4b39;
    color: #fff;
  }
}

.btn-outline-linkedin {
  background-color: #fff;
  color: #0976b4;
  border-color: #0976b4;

  &:hover,
  &:focus {
    background-color: #0976b4;
    color: #fff;
  }
}

.btn-outline-pinterest {
  color: #cc2127;
  background-color: #fff;
  border-color: #cc2127;

  &:hover,
  &:focus {
    background-color: #cc2127;
    color: #fff;
  }
}

.btn-outline-youtube {
  color: #e52d27;
  background-color: #fff;
  border-color: #e52d27;

  &:hover,
  &:focus {
    background-color: #e52d27;
    color: #fff;
  }
}

.btn-outline-tumblr {
  color: #35465c;
  background-color: #fff;
  border-color: #35465c;

  &:hover,
  &:focus {
    background-color: #35465c;
    color: #fff;
  }
}

.btn-outline-github {
  color: #333333;
  background-color: #fff;
  border-color: #333333;

  &:hover {
    background-color: #333333;
    color: #fff;
  }
}

.btn-github:focus {
  background-color: #333333;
  color: #fff;
}

.btn-outline-behance {
  color: #1769ff;
  background-color: #fff;
  border-color: #1769ff;

  &:hover,
  &:focus {
    background-color: #1769ff;
    color: #fff;
  }
}

.btn-outline-dribbble {
  color: #ea4c89;
  background-color: #fff;
  border-color: #ea4c89;

  &:hover,
  &:focus {
    background-color: #ea4c89;
    color: #fff;
  }
}

.btn-outline-skype {
  color: #00aff0;
  background-color: #fff;
  border-color: #00aff0;

  &:hover,
  &:focus {
    background-color: #00aff0;
    color: #fff;
  }
}

.btn-facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.btn-google-plus {
  background-color: #dd4b39;
  border-color: #dd4b39;
}

.btn-linkedin {
  background-color: #0976b4;
  border-color: #0976b4;
}

.btn-pinterest {
  background-color: #cc2127;
  border-color: #cc2127;
}

.btn-youtube {
  background-color: #e52d27;
  border-color: #e52d27;
}

.btn-tumblr {
  background-color: #35465c;
  border-color: #35465c;
}

.btn-github {
  background-color: #333333;
  border-color: #333333;
}

.btn-behance {
  background-color: #1769ff;
  border-color: #1769ff;
}

.btn-dribbble {
  background-color: #ea4c89;
  border-color: #ea4c89;
}

.btn-skype {
  background-color: #00aff0;
  border-color: #00aff0;
}
