$output-bourbon-deprecation-warnings: false;
@import "~bourbon/app/assets/stylesheets/_bourbon";
@import "partials/variables"; // colors, fonts etc...

@import "partials/mixins"; // custom mixins

@import "partials/layout"; // responsive grid and media queries

.cd-horizontal-timeline {
  opacity: 0;
  margin: 2em auto;
  @include transition(opacity 0.2s);

  &::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: "mobile";
    display: none;
  }

  &.loaded {
    /* show the timeline after events position has been set (using JavaScript) */
    opacity: 1;
  }

  .timeline {
    position: relative;
    height: 100px;
    width: 90%;
    margin: 0 auto;
    ul,
    ol {
      li {
        list-style: none;
      }
    }
  }

  .events-wrapper {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden;

    &::after,
    &::before {
      /* these are used to create a shadow effect at the sides of the timeline */
      content: "";
      position: absolute;
      z-index: 2;
      top: 0;
      height: 100%;
      width: 20px;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  .events {
    /* this is the grey line/timeline */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 49px;
    height: 2px;
    /* width will be set using JavaScript */
    background: darken($color-4, 10%);
    @include transition(transform 0.4s);
  }

  .filling-line {
    /* this is used to create the green line filling the timeline */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: theme-color("primary");
    @include transform(scaleX(0));
    @include transform-origin(left center);
    @include transition(transform 0.3s);
  }

  .events a {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    padding-bottom: 15px;
    color: $color-1;
    /* fix bug on Safari - text flickering while timeline translates */
    @include transform(translateZ(0));

    &::after {
      /* this is used to create the event spot */
      content: "";
      position: absolute;
      @include center(x);
      bottom: -5px;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      border: 2px solid #dfdede;
      background-color: white;
      @include transition(background-color 0.3s, border-color 0.3s);
    }

    .no-touch &:hover::after {
      background-color: theme-color("primary");
      border-color: theme-color("primary");
    }

    &.selected {
      pointer-events: none;

      &::after {
        background-color: theme-color("primary");
        border-color: theme-color("primary");
      }
    }

    &.older-event::after {
      border-color: theme-color("primary");
    }
  }

  @include MQ(L) {
    margin: 6em auto;

    &::before {
      /* never visible - this is used in jQuery to check the current MQ */
      content: "desktop";
    }
  }
}

.cd-timeline-navigation a {
  /* these are the left/right arrows to navigate the timeline */
  position: absolute;
  z-index: 1;
  @include center(y);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid darken($color-4, 10%);
  /* replace text with an icon */
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  @include transition(border-color 0.3s);

  &::after {
    /* arrow icon */
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    @include center;
    background: url(./../../../images/svg/cd-arrow.svg) no-repeat 0 0;
  }

  &.prev {
    left: 0;
    @include transform(translateY(-50%) rotate(180deg));
  }

  &.next {
    right: 0;
  }

  .no-touch &:hover {
    border-color: theme-color("primary");
  }

  &.inactive {
    cursor: not-allowed;

    &::after {
      background-position: 0 -16px;
    }

    .no-touch &:hover {
      border-color: darken($color-4, 10%);
    }
  }
}

.cd-horizontal-timeline .events-content {
  position: relative;
  width: 100%;
  margin: 2em 0;
  overflow: hidden;
  @include transition(height 0.4s);

  li {
    list-style: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    @include transform(translateX(-100%));
    padding: 0 5%;
    opacity: 0;
    @include animation-duration(0.4s);
    @include animation-timing-function(ease-in-out);

    &.selected {
      /* visible event content */
      position: relative;
      z-index: 2;
      opacity: 1;
      @include transform(translateX(0));
    }

    &.enter-right,
    &.leave-right {
      @include animation-name(cd-enter-right);
    }

    &.enter-left,
    &.leave-left {
      @include animation-name(cd-enter-left);
    }

    &.leave-right,
    &.leave-left {
      @include animation-direction(reverse);
    }
  }

  li > * {
    margin: 0 auto;
  }

  em {
    display: block;
    font-style: italic;
    margin: 10px auto;

    &::before {
      content: "- ";
    }
  }

  em,
  p {
    line-height: 1.6;
  }
}

@include keyframes(cd-enter-right) {
  0% {
    opacity: 0;
    @include transform(translateX(100%));
  }

  100% {
    opacity: 1;
    @include transform(translateX(0%));
  }
}

@include keyframes(cd-enter-left) {
  0% {
    opacity: 0;
    @include transform(translateX(-100%));
  }

  100% {
    opacity: 1;
    @include transform(translateX(0%));
  }
}
