@media (min-width: 576px) {
    .card-deck {
      margin-right: -12.5px;
      margin-left: -12.5px;
    }
  
    .card-deck .card {
      margin-right: 12.5px;
      margin-left: 12.5px;
    }
  }
  