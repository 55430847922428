/* Forms */

label {
  color: #585858;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 10px;
}

form .form-header {
  color: #353434;
  line-height: 3rem;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.color-forms {
  input.form-control {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.25);
    border-color: rgba(255, 255, 255, 0.6);

    &:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0);
      border-color: rgba(255, 255, 255, 0.6);
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
    }
  }

  label {
    color: #fff;
  }

  input::placeholder {
    color: #fff !important;
    font-size: 13px;
    opacity: 0.5 !important;

    /* Firefox */
  }
}

.input-group .btn {
  box-shadow: none;
  padding: 0.375rem 0.75rem;
}

.input-group-text {
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}

.col-form-label {
  font-size: 13px;
}

.form-control {
  border: 1px solid #ced4da;
  background-color: rgba(0, 0, 0, 0);
  color: #495057 !important;

  &:focus {
    border: 1px solid #7934f3;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0.2rem rgba(121, 52, 243, 0.35);
  }
}

.form-control-rounded {
  border-radius: 30px !important;
}

.form-control-square {
  border-radius: 0px !important;
}

.form-control {
  &:disabled,
  &[readonly] {
    background-color: rgba(158, 158, 158, 0.33);
    opacity: 1;
  }
}

.form-control-xl {
  height: 60px !important;
  font-size: 26px !important;
}

.position-relative {
  position: relative !important;
}

.has-icon-left .form-control {
  padding-right: 0.85rem;
  padding-left: 2.9rem;
}

.form-control-position {
  position: absolute;
  top: -8px;
  right: 0;
  z-index: 2;
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
}

.has-icon-left .form-control-position {
  right: auto;
  left: 0px;
}

.has-icon-right {
  .form-control-position {
    right: 0px;
    left: auto;
  }

  .form-control {
    padding-right: 37px;
  }
}

.search-input {
  margin-bottom: 10px;
}

.custom-header {
  background: rgba(255, 255, 255, 0.34);
  padding: 5px;
  color: #ffffff;
}

.input-group-prepend {
  [type="checkbox"] {
    &:checked,
    &:not(:checked) {
      position: initial;
      opacity: 1;
      margin-top: 0px;
    }
  }

  [type="radio"] {
    &:checked,
    &:not(:checked) {
      position: initial;
      opacity: 1;
      margin-top: 0px;
    }
  }
}
