/* Responsive */

@media only screen and (max-width: 1280px) {
  .search-bar {
    padding: 0rem 1rem 0 1rem;
  }
}

@media only screen and (max-width: 1199px) {
  .row.row-group > div {
    border-right: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);

    &:last-child {
      border-right: none;
      border-bottom: 0;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .search-bar {
    padding: 0rem 15px;
  }

  .sidebar {
    .brand-logo {
      position: absolute;
      background: #fff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .close-btn {
      display: block;
    }
  }
  .nav-tabs .nav-link {
    padding: 10px 10px;
  }

  #sidebar-wrapper {
    top: 0px;
    left: -250px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    box-shadow: none;

    .navigation {
      //margin-top: 60px;
    }

    .sidebar-content {
      margin-top: 60px;
    }
  }

  .toggle-menu {
    margin-left: 0px;
  }

  .card {
    margin-bottom: 25px;
  }

  .card-deck {
    margin-bottom: 0px;

    .card {
      margin-bottom: 25px;
    }
  }

  .card-group {
    margin-bottom: 25px;
  }

  .content-wrapper {
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .topbar-nav .navbar {
    padding: 0px 15px;
  }

  .footer {
    position: absolute;
    left: 0px;
  }

  #wrapper.nav-collapsed {
    #sidebar-wrapper {
      position: fixed;
      top: 0px;
      left: 0px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
      background: #000;
      opacity: 0.6;
      z-index: 999;
      display: block;
    }

    .toggle-menu,
    .content-wrapper {
      margin-left: 0px;
    }

    .footer {
      position: absolute;
      left: 0px;
    }
  }

  .hidden-xs {
    display: none !important;
  }

  .height-100v {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .search-bar {
    display: none;

    input {
      height: 60px;
      border: 1px solid rgba(255, 255, 255, 0);
      background-color: rgba(255, 255, 255, 0);
      border-radius: 0px;

      &:focus {
        border: 1px solid rgba(255, 255, 255, 0);
        background-color: rgba(255, 255, 255, 0);
        box-shadow: none;
      }
    }

    .btn-search {
      background: rgba(255, 255, 255, 0);
      border: 1px solid rgba(255, 255, 255, 0);
      border-radius: 0px;
      padding: 0 15px !important;
    }

    .btn-search-back {
      background: rgba(255, 255, 255, 0);
      border: 1px solid rgba(255, 255, 255, 0);
      padding: 0 15px;
    }
  }

  .search-arrow-back {
    display: flex;
    margin-right: 0px;
  }

  .full-search-bar {
    display: flex;
    align-items: center;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 100;
    background: #fff;
    height: 60px;
    padding: 0rem 0rem;
  }

  .search-btn-mobile {
    display: flex;
  }

  .topbar-nav .navbar {
    .dropdown {
      position: static !important;
    }

    .dropdown-menu {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 575px) {
  .bg-signup2 {
    height: 35rem;
    border-radius: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .bg-signin2 {
    height: 25rem;
    border-radius: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .bg-reset-password2 {
    height: 20rem;
    border-radius: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .search-bar .btn-search {
    padding: 0 20px;
  }
}
