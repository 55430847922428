/* User Details */

.user-profile img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.user-details .media {
  .avatar img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .media-body {
    .user-title {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.84);
      margin-bottom: 2px;
    }

    .user-subtitle {
      font-size: 13px;
      color: #232323;
      margin-bottom: 0;
    }
  }
}

/*Cards */

.card {
  margin-bottom: 25px;
  box-shadow: 0 5px 15px rgba(57, 98, 254, 0.02),
    0 3px 6px rgba(117, 108, 254, 0.12);
  border: none;
  background-color: #ffffff;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.84);
}

.card-title {
  margin-bottom: 0.75rem;
  font-weight: 600;
  font-size: 16px;
  color: #353434;
}

.card-action {
  float: right;

  a i {
    color: #353434;
    border-radius: 50%;
  }
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.card-deck {
  margin-bottom: 25px;
}

@media (min-width: 576px) {
  .card-deck {
    margin-right: -12.5px;
    margin-left: -12.5px;

    .card {
      margin-right: 12.5px;
      margin-left: 12.5px;
    }
  }
}

.card-group {
  box-shadow: 0 5px 15px rgba(57, 98, 254, 0.02),
    0 3px 6px rgba(117, 108, 254, 0.12);
  margin-bottom: 25px;

  .card {
    box-shadow: none;
  }
}

.bubble {
  position: relative;
  overflow: hidden;

  .card-block {
    &:before,
    &:after {
      content: "";
      position: absolute;
      background-color: rgba(255, 255, 255, 0.22);
      right: -40px;
      border-radius: 50%;
    }

    &:before {
      width: 100px;
      height: 100px;
      bottom: -40px;
    }

    &:after {
      width: 80px;
      height: 80px;
      bottom: 40px;
    }
  }
}

/*Profile card 2*/

.profile-card-2 {
  .card-img-block {
    float: left;
    width: 100%;
    height: 150px;
    overflow: hidden;
  }

  .card-body {
    position: relative;
  }

  .profile {
    border-radius: 50%;
    position: absolute;
    top: -42px;
    left: 15%;
    max-width: 75px;
    border: 3px solid rgba(255, 255, 255, 1);
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  }

  h5 {
    font-weight: 600;
  }

  .card-text {
    font-weight: 300;
    font-size: 15px;
  }

  .icon-block {
    float: left;
    width: 100%;

    a {
      text-decoration: none;
    }
  }

  i {
    display: inline-block;
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    margin: 0 5px;
  }
}

.profile-card img {
  border-radius: 50%;
  padding: 4px;
  background-color: #fff;
  border: 1px solid #c4c1da;
}

.profile-cover {
  position: relative;
  width: 100%;
  height: 125px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-size: cover;
  background-position: center;
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
}

.profile-cover-img1 {
  background-image: url(./../../../images/gallery/32.jpg);
}

.profile-cover-img2 {
  background-image: url(./../../../images/gallery/31.jpg);
}

.profile-cover-img3 {
  background-image: url(./../../../images/gallery/27.jpg);
}

.profile-cover-img4 {
  background-image: url(./../../../images/gallery/33.jpg);
}

.border-angle {
  border-top-left-radius: 60px !important;
  border-bottom-right-radius: 60px !important;
}

.card-img-1 {
  background-image: url(./../../../images/gallery/38.jpg);
  background-size: cover;
  background-position: center;
}

.card-img-2 {
  background-image: url(./../../../images/gallery/40.jpg);
  background-size: cover;
  background-position: center;
}

.card-img-3 {
  background-image: url(./../../../images/gallery/41.jpg);
  background-size: cover;
  background-position: center;
}

.overlay-div1 {
  background-image: url(https://images.pexels.com/photos/1391580/pexels-photo-1391580.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  width: 100%;
  height: 520px;
  border-radius: 0.25rem;
  background-size: cover;
  background-position: center;
}

.overlay-div2 {
  background-image: url(https://images.pexels.com/photos/2175346/pexels-photo-2175346.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  width: 100%;
  height: 520px;
  border-radius: 0.25rem;
  background-size: cover;
  background-position: center;
}

.overlay-div3 {
  background-image: url(https://images.pexels.com/photos/2344096/pexels-photo-2344096.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  width: 100%;
  height: 520px;
  border-radius: 0.25rem;
  background-size: cover;
  background-position: center;
}
